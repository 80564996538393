/*purgecss start ignore
 */

/**
 * Here we add custom component classes; stuff we want loaded
 * *before* the utilities so that the utilities can still
 * override them.
 *
 */

/**
* components/global.css
 *
 * Project-wide styles
 *
 */

/* Smooth scroll */

html {
  scroll-behavior: smooth;
  scroll-padding-top: 50px
}

@media (min-width: 1024px) {

html {
    scroll-padding-top: 150px
}
  }

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* Fade box */

.fade-box img {
  transition: opacity 200ms, filter 500ms;
  filter: blur(10px);
}

.fade-box .lazyload,
.fade-box .lazyloading {
  opacity: 0;
}

.fade-box img.lazyloaded {
  opacity: 1;
  filter: blur(0);
}

/* Buttons */

.buttons button:after {
  content: "";
  display: block;
  position: absolute;
  right: -1.5rem;
  width: 1.5rem;
  height: 1px;
}

/* FORMS */

.radio input[type="radio"]:checked+label {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 188, 5, var(--tw-bg-opacity))
}

.invalid {
  border-color: darkred;
  background-color: hsl(0, 30%, 95%);
  margin-bottom: 0em;
}

.error-message {
  margin-bottom: 1em;
  color: hsl(0deg, 100%, 15%);
}

/*
.hf-warning {
    background: linear-gradient(to bottom, rgba(255,255,255,.9), rgba(255,255,255,.75));
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 1px;
    box-shadow: 0 12px 10px -10px rgba(0,0,0,.5);
    box-sizing: border-box;
    max-width: 100%;
    color: #621;
    font-size: 14px;
    line-height: 18px;
    padding: .25em .5em;
    pointer-events: none;
    white-space: pre-line;
  }

  .hf-warning:empty {
    display: none;
  }


  .hf-invalid + .hf-warning {
    display: none;
    position: absolute;
  }
  :invalid + .hf-warning {
    display: none;
    position: absolute;
  }

  .hf-invalid:focus + .hf-warning:not(:empty) {
    display: block;
  }
  :invalid:focus + .hf-warning:not(:empty) {
    display: block;
  } */

/* Cookies */

a.cc-link {
  display: none;
}

.cc-message h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

/* Newsletter */

._form_hide { display:none; visibility:hidden;
}

._form_show { display:block; visibility:visible;
}

#_form_8_ ._field-wrapper { position:relative;
}

#_form_8_ input[type="text"]._has_error,#_form_8_ textarea._has_error { border:#f37c7b 1px solid;
}

#_form_8_ input[type="checkbox"]._has_error { outline:#f37c7b 1px solid;
}

#_form_8_ ._error { display:block; position:absolute; font-size:0.875rem; z-index:10000001;
}

#_form_8_ ._error._above { padding-bottom:4px; bottom:39px; right:10px;
}

#_form_8_ ._error._below { padding-top:4px; top:100%; right:0;
}

#_form_8_ ._error._above ._error-arrow { bottom:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-top:5px solid #f37c7b;
}

#_form_8_ ._error._below ._error-arrow { top:0; right:15px; border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:5px solid #f37c7b;
}

#_form_8_ ._error-inner { padding:8px 12px; background-color:#f37c7b; font-size:0.875rem; font-family:arial, sans-serif; color:#fff; text-align:center; text-decoration:none; border-radius:4px;
}

#_form_8_ ._error-inner._form_error { margin-bottom:5px; text-align:left;
}

#_form_8_ ._error-inner._no_arrow { margin-bottom:10px;
}

#_form_8_ ._error-arrow { position:absolute; width:0; height:0;
}

#_form_8_ ._error-html { margin-bottom:10px;
}

.pika-single { z-index:10000001 !important;
}

/**
 * components/typography.css
 * 
 * Typography rules.
 *
 */

/**
 * components/webfonts.css
 * 
 * Project webfonts.
 *
 */

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-LightItalic.eot);

  src: local('DIN 2014 Light Italic'), local('DIN2014-LightItalic'),
        url(/dist/fonts/DIN2014-LightItalic.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-LightItalic.woff) format('woff'),
        url(/dist/fonts/DIN2014-LightItalic.ttf) format('truetype');

  font-weight: 300;

  font-style: italic;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-Light.eot);

  src: local('DIN 2014 Light'), local('DIN2014-Light'),
        url(/dist/fonts/DIN2014-Light.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-Light.woff) format('woff'),
        url(/dist/fonts/DIN2014-Light.ttf) format('truetype');

  font-weight: 300;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-ExtraBoldItalic.eot);

  src: local('DIN 2014 ExtraBold Italic'), local('DIN2014-ExtraBoldItalic'),
        url(/dist/fonts/DIN2014-ExtraBoldItalic.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-ExtraBoldItalic.woff) format('woff'),
        url(/dist/fonts/DIN2014-ExtraBoldItalic.ttf) format('truetype');

  font-weight: 800;

  font-style: italic;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-ExtraLight.eot);

  src: local('DIN 2014 ExtraLight'), local('DIN2014-ExtraLight'),
        url(/dist/fonts/DIN2014-ExtraLight.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-ExtraLight.woff) format('woff'),
        url(/dist/fonts/DIN2014-ExtraLight.ttf) format('truetype');

  font-weight: 200;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-ExtraBold.eot);

  src: local('DIN 2014 ExtraBold'), local('DIN2014-ExtraBold'),
        url(/dist/fonts/DIN2014-ExtraBold.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-ExtraBold.woff) format('woff'),
        url(/dist/fonts/DIN2014-ExtraBold.ttf) format('truetype');

  font-weight: 800;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-ExtraLightItalic.eot);

  src: local('DIN 2014 ExtraLight Italic'), local('DIN2014-ExtraLightItalic'),
        url(/dist/fonts/DIN2014-ExtraLightItalic.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-ExtraLightItalic.woff) format('woff'),
        url(/dist/fonts/DIN2014-ExtraLightItalic.ttf) format('truetype');

  font-weight: 200;

  font-style: italic;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-Regular.eot);

  src: local('DIN 2014 Regular'), local('DIN2014-Regular'),
        url(/dist/fonts/DIN2014-Regular.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-Regular.woff) format('woff'),
        url(/dist/fonts/DIN2014-Regular.ttf) format('truetype');

  font-weight: normal;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-Italic.eot);

  src: local('DIN 2014 Italic'), local('DIN2014-Italic'),
        url(/dist/fonts/DIN2014-Italic.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-Italic.woff) format('woff'),
        url(/dist/fonts/DIN2014-Italic.ttf) format('truetype');

  font-weight: normal;

  font-style: italic;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-Bold.eot);

  src: local('DIN 2014 Bold'), local('DIN2014-Bold'),
        url(/dist/fonts/DIN2014-Bold.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-Bold.woff) format('woff'),
        url(/dist/fonts/DIN2014-Bold.ttf) format('truetype');

  font-weight: bold;

  font-style: normal;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-BoldItalic.eot);

  src: local('DIN 2014 Bold Italic'), local('DIN2014-BoldItalic'),
        url(/dist/fonts/DIN2014-BoldItalic.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-BoldItalic.woff) format('woff'),
        url(/dist/fonts/DIN2014-BoldItalic.ttf) format('truetype');

  font-weight: bold;

  font-style: italic;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-DemiBoldItalic.eot);

  src: local('DIN 2014 DemiBold Italic'), local('DIN2014-DemiBoldItalic'),
        url(/dist/fonts/DIN2014-DemiBoldItalic.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-DemiBoldItalic.woff) format('woff'),
        url(/dist/fonts/DIN2014-DemiBoldItalic.ttf) format('truetype');

  font-weight: 600;

  font-style: italic;

  font-display: swap;
}

@font-face {
  font-family: 'DIN 2014';

  src: url(/dist/fonts/DIN2014-DemiBold.eot);

  src: local('DIN 2014 DemiBold'), local('DIN2014-DemiBold'),
        url(/dist/fonts/DIN2014-DemiBold.eot?#iefix) format('embedded-opentype'),
        url(/dist/fonts/DIN2014-DemiBold.woff) format('woff'),
        url(/dist/fonts/DIN2014-DemiBold.ttf) format('truetype');

  font-weight: 600;

  font-style: normal;

  font-display: swap;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: currentColor;
}

.hamburger-box {
  width: 27px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 27px;
  height: 2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: currentColor;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -9px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger .hamburger-inner {
  top: 2px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger .hamburger-inner::before {
  top: 9px;
  transition: opacity 0.125s 0.275s ease;
}

.hamburger .hamburger-inner::after {
  top: 18px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(135deg);
  transition-delay: 0.075s;
}

.hamburger.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

.hamburger-sm .hamburger-box {
    width: 15px;
    height: 16px;
  }

.hamburger-sm .hamburger-inner,
    .hamburger-sm .hamburger-inner::before,
    .hamburger-sm .hamburger-inner::after {
    width: 15px;
    height: 2px;
  }

.hamburger-sm .hamburger-inner::before {
    top: 7px;
  }

.hamburger-sm .hamburger-inner::after {
    top: 14px;
  }

.hamburger-sm.is-active .hamburger-inner::after {
    transform: translate3d(0, -14px, 0) rotate(-270deg);
    transition-delay: 0.075s;
  }

.hamburger-sm.is-active .hamburger-inner {
    transform: translate3d(0, 8px, 0) rotate(135deg);
    transition-delay: 0.075s;
  }

.swiper {
  --swiper-theme-color: #D29F13;
  --swiper-navigation-color: #000000;
  --swiper-pagination-color: #D29F13;
  --swiper-pagination-bullet-inactive-color: #ffffff;
  --swiper-pagination-bullet-horizontal-gap: 6px
}

.swiper-slide {
  height: auto;
}

.swiper-pagination-bullet {
  opacity: 100;
}

.slideshow-swiper .swiper-pagination {
  bottom: 6.25rem;
  left: 1.5rem;
  text-align: left;
}

.slideshow-swiper.slideshow-full .swiper-pagination {
  bottom: 1rem;
  left: 1.5rem;
  text-align: left;
}

.slideshow-swiper .swiper-button-prev, .slideshow-swiper .swiper-button-next {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 188, 5, var(--tw-bg-opacity));
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.slideshow-swiper .swiper-button-prev,
.slideshow-swiper .swiper-button-next {
  width: 40px;
  height: 40px;
}

@media (min-width: 1024px) {

.slideshow-swiper .swiper-button-prev,
.slideshow-swiper .swiper-button-next {
    width: 65px;

    height: 65px;
}
  }

.slideshow-swiper .swiper-button-prev::after, .slideshow-swiper .swiper-button-next::after {
    content: "";
    width: 9px;
    height: 17px;
  }

@media (min-width: 1024px) {

.slideshow-swiper .swiper-button-prev::after, .slideshow-swiper .swiper-button-next::after {
      width: 13px;

      height: 22px;
  }
    }

.slideshow-swiper .swiper-button-prev::after, .slideshow-swiper .swiper-button-next::after {
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='11' height='18' viewBox='0 0 11 18' xmlns='http://www.w3.org/2000/svg' stroke='%23000'%3E %3Cpath d='M2 2l7 7-7 7' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E %3C/svg%3E");
    /* @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ; */
  }

.slideshow-swiper .swiper-button-prev:hover, .slideshow-swiper .swiper-button-next:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(210, 159, 19, var(--tw-bg-opacity));
  }

@media (min-width: 1024px) {

.slideshow-swiper .swiper-button-next {
    right: 2rem
}
  }

@media (min-width: 1024px) {

.slideshow-swiper .swiper-button-prev {
    left: 2rem
}
  }

.slideshow-swiper .swiper-button-prev::after {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-rotate: 180deg;
  }

.hyper-error {
  color: #FF3131;
}

/**
 * Include styles for individual pages
 *
 */

/**
 * pages/homepage.pcss
 *
 * Styles for the Home page.
 *
 */

/*purgecss end ignore
 */
