/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 */

.fixed{
  position:fixed
}

.absolute{
  position:absolute
}

.relative{
  position:relative
}

.sticky{
  position:-webkit-sticky;
  position:sticky
}

.inset-0{
  top:0px;
  right:0px;
  bottom:0px;
  left:0px
}

.top-0{
  top:0px
}

.top-6{
  top:1.5rem
}

.top-8{
  top:2rem
}

.top-10{
  top:2.5rem
}

.top-12{
  top:3rem
}

.top-20{
  top:5rem
}

.top-80{
  top:20rem
}

.top-90{
  top:90%
}

.-top-20{
  top:-5rem
}

.-top-28{
  top:-7rem
}

.top-full{
  top:100%
}

.right-0{
  right:0px
}

.right-4{
  right:1rem
}

.right-6{
  right:1.5rem
}

.right-16{
  right:4rem
}

.-right-12{
  right:-3rem
}

.-right-20{
  right:-5rem
}

.-right-24{
  right:-6rem
}

.-right-28{
  right:-7rem
}

.bottom-0{
  bottom:0px
}

.bottom-3{
  bottom:0.75rem
}

.bottom-4{
  bottom:1rem
}

.bottom-6{
  bottom:1.5rem
}

.bottom-10{
  bottom:2.5rem
}

.bottom-16{
  bottom:4rem
}

.-bottom-8{
  bottom:-2rem
}

.left-0{
  left:0px
}

.left-3{
  left:0.75rem
}

.left-4{
  left:1rem
}

.left-6{
  left:1.5rem
}

.z-0{
  z-index:0
}

.z-1{
  z-index:1
}

.z-10{
  z-index:10
}

.z-20{
  z-index:20
}

.z-40{
  z-index:40
}

.z-50{
  z-index:50
}

.z-100{
  z-index:100
}

.z-300{
  z-index:300
}

.z-400{
  z-index:400
}

.z-500{
  z-index:500
}

.z-600{
  z-index:600
}

.-z-1{
  z-index:-1
}

.order-1{
  order:1
}

.order-2{
  order:2
}

.col-span-1{
  grid-column:span 1 / span 1
}

.col-span-2{
  grid-column:span 2 / span 2
}

.col-span-3{
  grid-column:span 3 / span 3
}

.mx-2{
  margin-left:0.5rem;
  margin-right:0.5rem
}

.mx-4{
  margin-left:1rem;
  margin-right:1rem
}

.mx-auto{
  margin-left:auto;
  margin-right:auto
}

.my-4{
  margin-top:1rem;
  margin-bottom:1rem
}

.my-auto{
  margin-top:auto;
  margin-bottom:auto
}

.mt-1{
  margin-top:0.25rem
}

.mt-2{
  margin-top:0.5rem
}

.mt-4{
  margin-top:1rem
}

.mt-6{
  margin-top:1.5rem
}

.mt-8{
  margin-top:2rem
}

.mt-9{
  margin-top:2.25rem
}

.mt-10{
  margin-top:2.5rem
}

.mt-12{
  margin-top:3rem
}

.mt-20{
  margin-top:5rem
}

.mt-44{
  margin-top:11rem
}

.mt-auto{
  margin-top:auto
}

.-mt-1{
  margin-top:-0.25rem
}

.-mt-8{
  margin-top:-2rem
}

.-mt-20{
  margin-top:-5rem
}

.-mt-32{
  margin-top:-8rem
}

.mr-1{
  margin-right:0.25rem
}

.mr-2{
  margin-right:0.5rem
}

.mr-4{
  margin-right:1rem
}

.mr-6{
  margin-right:1.5rem
}

.mr-8{
  margin-right:2rem
}

.-mr-6{
  margin-right:-1.5rem
}

.mb-1{
  margin-bottom:0.25rem
}

.mb-2{
  margin-bottom:0.5rem
}

.mb-3{
  margin-bottom:0.75rem
}

.mb-4{
  margin-bottom:1rem
}

.mb-6{
  margin-bottom:1.5rem
}

.mb-8{
  margin-bottom:2rem
}

.mb-12{
  margin-bottom:3rem
}

.mb-16{
  margin-bottom:4rem
}

.mb-20{
  margin-bottom:5rem
}

.mb-32{
  margin-bottom:8rem
}

.-mb-40{
  margin-bottom:-10rem
}

.ml-0{
  margin-left:0px
}

.ml-1{
  margin-left:0.25rem
}

.ml-2{
  margin-left:0.5rem
}

.ml-3{
  margin-left:0.75rem
}

.ml-6{
  margin-left:1.5rem
}

.ml-auto{
  margin-left:auto
}

.ml-0\.5{
  margin-left:0.125rem
}

.-ml-1{
  margin-left:-0.25rem
}

.block{
  display:block
}

.inline-block{
  display:inline-block
}

.inline{
  display:inline
}

.flex{
  display:flex
}

.inline-flex{
  display:inline-flex
}

.table{
  display:table
}

.grid{
  display:grid
}

.hidden{
  display:none
}

.h-3{
  height:0.75rem
}

.h-5{
  height:1.25rem
}

.h-6{
  height:1.5rem
}

.h-80{
  height:20rem
}

.h-auto{
  height:auto
}

.h-3\.5{
  height:0.875rem
}

.h-full{
  height:100%
}

.h-screen{
  height:100vh
}

.w-3{
  width:0.75rem
}

.w-4{
  width:1rem
}

.w-5{
  width:1.25rem
}

.w-6{
  width:1.5rem
}

.w-8{
  width:2rem
}

.w-10{
  width:2.5rem
}

.w-25{
  width:6.25rem
}

.w-28{
  width:7rem
}

.w-40{
  width:10rem
}

.w-52{
  width:13rem
}

.w-56{
  width:14rem
}

.w-60{
  width:15rem
}

.w-64{
  width:16rem
}

.w-72{
  width:18rem
}

.w-96{
  width:24rem
}

.w-112{
  width:28rem
}

.w-3\.5{
  width:0.875rem
}

.w-full{
  width:100%
}

.w-screen{
  width:100vw
}

.min-w-60{
  min-width:15rem
}

.max-w-none{
  max-width:none
}

.max-w-xs{
  max-width:20rem
}

.max-w-2xl{
  max-width:42rem
}

.max-w-3xl{
  max-width:48rem
}

.max-w-5xl{
  max-width:64rem
}

.max-w-7xl{
  max-width:80rem
}

.max-w-full{
  max-width:100%
}

.max-w-12xl{
  max-width:120rem
}

.flex-1{
  flex:1 1 0%
}

.flex-auto{
  flex:1 1 auto
}

.flex-shrink-0{
  flex-shrink:0
}

.flex-grow-0{
  flex-grow:0
}

.flex-grow{
  flex-grow:1
}

.transform{
  --tw-translate-x:0;
  --tw-translate-y:0;
  --tw-rotate:0;
  --tw-skew-x:0;
  --tw-skew-y:0;
  --tw-scale-x:1;
  --tw-scale-y:1;
  transform:translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.translate-x-0{
  --tw-translate-x:0px
}

.-translate-x-full{
  --tw-translate-x:-100%
}

.translate-y-0{
  --tw-translate-y:0px
}

.-translate-y-1{
  --tw-translate-y:-0.25rem
}

.translate-y-full{
  --tw-translate-y:100%
}

.rotate-60{
  --tw-rotate:60deg
}

.rotate-70{
  --tw-rotate:70deg
}

.rotate-75{
  --tw-rotate:75deg
}

.scale-95{
  --tw-scale-x:.95;
  --tw-scale-y:.95
}

.scale-100{
  --tw-scale-x:1;
  --tw-scale-y:1
}

@-webkit-keyframes spin{
  to{
    transform:rotate(360deg)
  }
}

@keyframes spin{
  to{
    transform:rotate(360deg)
  }
}

@-webkit-keyframes ping{
  75%, 100%{
    transform:scale(2);
    opacity:0
  }
}

@keyframes ping{
  75%, 100%{
    transform:scale(2);
    opacity:0
  }
}

@-webkit-keyframes pulse{
  50%{
    opacity:.5
  }
}

@keyframes pulse{
  50%{
    opacity:.5
  }
}

@-webkit-keyframes bounce{
  0%, 100%{
    transform:translateY(-25%);
    -webkit-animation-timing-function:cubic-bezier(0.8,0,1,1);
            animation-timing-function:cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform:none;
    -webkit-animation-timing-function:cubic-bezier(0,0,0.2,1);
            animation-timing-function:cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce{
  0%, 100%{
    transform:translateY(-25%);
    -webkit-animation-timing-function:cubic-bezier(0.8,0,1,1);
            animation-timing-function:cubic-bezier(0.8,0,1,1)
  }

  50%{
    transform:none;
    -webkit-animation-timing-function:cubic-bezier(0,0,0.2,1);
            animation-timing-function:cubic-bezier(0,0,0.2,1)
  }
}

.cursor-pointer{
  cursor:pointer
}

.cursor-not-allowed{
  cursor:not-allowed
}

.resize{
  resize:both
}

.appearance-none{
  -webkit-appearance:none;
     -moz-appearance:none;
          appearance:none
}

.grid-cols-2{
  grid-template-columns:repeat(2, minmax(0, 1fr))
}

.grid-cols-3{
  grid-template-columns:repeat(3, minmax(0, 1fr))
}

.flex-row{
  flex-direction:row
}

.flex-col{
  flex-direction:column
}

.flex-wrap{
  flex-wrap:wrap
}

.items-start{
  align-items:flex-start
}

.items-center{
  align-items:center
}

.justify-end{
  justify-content:flex-end
}

.justify-center{
  justify-content:center
}

.justify-between{
  justify-content:space-between
}

.gap-2{
  grid-gap:0.5rem;
  gap:0.5rem
}

.gap-3{
  grid-gap:0.75rem;
  gap:0.75rem
}

.gap-4{
  grid-gap:1rem;
  gap:1rem
}

.gap-6{
  grid-gap:1.5rem;
  gap:1.5rem
}

.gap-x-4{
  grid-column-gap:1rem;
  -moz-column-gap:1rem;
       column-gap:1rem
}

.gap-y-2{
  grid-row-gap:0.5rem;
  row-gap:0.5rem
}

.space-x-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(0.5rem * var(--tw-space-x-reverse));
  margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(1rem * var(--tw-space-x-reverse));
  margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(2rem * var(--tw-space-x-reverse));
  margin-left:calc(2rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-x-12 > :not([hidden]) ~ :not([hidden]){
  --tw-space-x-reverse:0;
  margin-right:calc(3rem * var(--tw-space-x-reverse));
  margin-left:calc(3rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-2 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(0.5rem * var(--tw-space-y-reverse))
}

.space-y-3 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(0.75rem * var(--tw-space-y-reverse))
}

.space-y-4 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1rem * var(--tw-space-y-reverse))
}

.space-y-6 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(1.5rem * var(--tw-space-y-reverse))
}

.space-y-8 > :not([hidden]) ~ :not([hidden]){
  --tw-space-y-reverse:0;
  margin-top:calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom:calc(2rem * var(--tw-space-y-reverse))
}

.overflow-hidden{
  overflow:hidden
}

.overflow-visible{
  overflow:visible
}

.overflow-x-auto{
  overflow-x:auto
}

.overflow-y-auto{
  overflow-y:auto
}

.overflow-x-hidden{
  overflow-x:hidden
}

.overflow-y-visible{
  overflow-y:visible
}

.overflow-x-scroll{
  overflow-x:scroll
}

.rounded-sm{
  border-radius:0.125rem
}

.rounded-md{
  border-radius:0.35rem
}

.rounded-lg{
  border-radius:0.5rem
}

.rounded-full{
  border-radius:9999px
}

.rounded-1xl{
  border-radius:0.5625rem
}

.rounded-tl-1xl{
  border-top-left-radius:0.5625rem
}

.rounded-tr-xl{
  border-top-right-radius:0.75rem
}

.rounded-tr-1xl{
  border-top-right-radius:0.5625rem
}

.rounded-br-4xl{
  border-bottom-right-radius:1.875rem
}

.rounded-br-5xl{
  border-bottom-right-radius:2.5rem
}

.border-0{
  border-width:0px
}

.border-1{
  border-width:1px
}

.border-2{
  border-width:2px
}

.border{
  border-width:1px
}

.border-1\.5{
  border-width:1.5px
}

.border-b{
  border-bottom-width:1px
}

.border-none{
  border-style:none
}

.border-black-50{
  --tw-border-opacity:1;
  border-color:rgba(217, 217, 217, var(--tw-border-opacity))
}

.border-black-200{
  --tw-border-opacity:1;
  border-color:rgba(170, 170, 170, var(--tw-border-opacity))
}

.border-black{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.border-white{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.border-gold{
  --tw-border-opacity:1;
  border-color:rgba(255, 188, 5, var(--tw-border-opacity))
}

.border-green{
  --tw-border-opacity:1;
  border-color:rgba(13, 188, 47, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-black{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-white{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.group:hover .group-hover\:border-gold{
  --tw-border-opacity:1;
  border-color:rgba(255, 188, 5, var(--tw-border-opacity))
}

.hover\:border-black:hover{
  --tw-border-opacity:1;
  border-color:rgba(0, 0, 0, var(--tw-border-opacity))
}

.hover\:border-white:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 255, 255, var(--tw-border-opacity))
}

.hover\:border-gold:hover{
  --tw-border-opacity:1;
  border-color:rgba(255, 188, 5, var(--tw-border-opacity))
}

.border-opacity-20{
  --tw-border-opacity:0.2
}

.bg-transparent{
  background-color:transparent
}

.bg-black-200{
  --tw-bg-opacity:1;
  background-color:rgba(170, 170, 170, var(--tw-bg-opacity))
}

.bg-black-light{
  --tw-bg-opacity:1;
  background-color:rgba(31, 31, 31, var(--tw-bg-opacity))
}

.bg-black{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.bg-white{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.bg-beige{
  --tw-bg-opacity:1;
  background-color:rgba(246, 244, 236, var(--tw-bg-opacity))
}

.bg-gold{
  --tw-bg-opacity:1;
  background-color:rgba(255, 188, 5, var(--tw-bg-opacity))
}

.bg-gold-dark{
  --tw-bg-opacity:1;
  background-color:rgba(210, 159, 19, var(--tw-bg-opacity))
}

.bg-green{
  --tw-bg-opacity:1;
  background-color:rgba(13, 188, 47, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-black{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-white{
  --tw-bg-opacity:1;
  background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
}

.group:hover .group-hover\:bg-gold{
  --tw-bg-opacity:1;
  background-color:rgba(255, 188, 5, var(--tw-bg-opacity))
}

.hover\:bg-black:hover{
  --tw-bg-opacity:1;
  background-color:rgba(0, 0, 0, var(--tw-bg-opacity))
}

.bg-gradient-to-t{
  background-image:linear-gradient(to top, var(--tw-gradient-stops))
}

.from-black-light{
  --tw-gradient-from:#1F1F1F;
  --tw-gradient-stops:var(--tw-gradient-from), var(--tw-gradient-to, rgba(31, 31, 31, 0))
}

.via-black-light{
  --tw-gradient-stops:var(--tw-gradient-from), #1F1F1F, var(--tw-gradient-to, rgba(31, 31, 31, 0))
}

.to-transparent{
  --tw-gradient-to:transparent
}

.fill-current{
  fill:currentColor
}

.stroke-current{
  stroke:currentColor
}

.object-cover{
  -o-object-fit:cover;
     object-fit:cover
}

.p-0{
  padding:0px
}

.p-6{
  padding:1.5rem
}

.p-0\.5{
  padding:0.125rem
}

.px-3{
  padding-left:0.75rem;
  padding-right:0.75rem
}

.px-4{
  padding-left:1rem;
  padding-right:1rem
}

.px-6{
  padding-left:1.5rem;
  padding-right:1.5rem
}

.px-8{
  padding-left:2rem;
  padding-right:2rem
}

.py-0{
  padding-top:0px;
  padding-bottom:0px
}

.py-1{
  padding-top:0.25rem;
  padding-bottom:0.25rem
}

.py-2{
  padding-top:0.5rem;
  padding-bottom:0.5rem
}

.py-3{
  padding-top:0.75rem;
  padding-bottom:0.75rem
}

.py-4{
  padding-top:1rem;
  padding-bottom:1rem
}

.py-6{
  padding-top:1.5rem;
  padding-bottom:1.5rem
}

.py-8{
  padding-top:2rem;
  padding-bottom:2rem
}

.py-10{
  padding-top:2.5rem;
  padding-bottom:2.5rem
}

.py-0\.5{
  padding-top:0.125rem;
  padding-bottom:0.125rem
}

.py-1\.5{
  padding-top:0.375rem;
  padding-bottom:0.375rem
}

.pt-4{
  padding-top:1rem
}

.pt-6{
  padding-top:1.5rem
}

.pt-10{
  padding-top:2.5rem
}

.pt-12{
  padding-top:3rem
}

.pt-40{
  padding-top:10rem
}

.pr-8{
  padding-right:2rem
}

.pb-3{
  padding-bottom:0.75rem
}

.pb-8{
  padding-bottom:2rem
}

.pb-12{
  padding-bottom:3rem
}

.pb-20{
  padding-bottom:5rem
}

.text-left{
  text-align:left
}

.text-center{
  text-align:center
}

.text-right{
  text-align:right
}

.font-sans{
  font-family:DIN\ 2014, Roboto, Arial, sans-serif
}

.text-sm{
  font-size:0.875rem;
  line-height:1.25rem
}

.text-base{
  font-size:1rem;
  line-height:1.5rem
}

.text-lg{
  font-size:1.125rem;
  line-height:1.75rem
}

.text-xl{
  font-size:1.25rem;
  line-height:1.75rem
}

.text-2xl{
  font-size:1.5rem;
  line-height:2rem
}

.text-3xl{
  font-size:1.875rem;
  line-height:2.25rem
}

.text-1xl{
  font-size:1.3125rem;
  line-height:1.75rem
}

.text-3\.5xl{
  font-size:2rem;
  line-height:2.3125rem
}

.font-light{
  font-weight:300
}

.font-normal{
  font-weight:400
}

.font-medium{
  font-weight:500
}

.font-semibold{
  font-weight:600
}

.font-bold{
  font-weight:700
}

.uppercase{
  text-transform:uppercase
}

.leading-none{
  line-height:1
}

.leading-tight{
  line-height:1.25
}

.leading-snug{
  line-height:1.375
}

.text-black-200{
  --tw-text-opacity:1;
  color:rgba(170, 170, 170, var(--tw-text-opacity))
}

.text-black-400{
  --tw-text-opacity:1;
  color:rgba(124, 124, 124, var(--tw-text-opacity))
}

.text-black-500{
  --tw-text-opacity:1;
  color:rgba(92, 92, 92, var(--tw-text-opacity))
}

.text-black-lightest{
  --tw-text-opacity:1;
  color:rgba(76, 75, 74, var(--tw-text-opacity))
}

.text-black-lighter{
  --tw-text-opacity:1;
  color:rgba(45, 41, 38, var(--tw-text-opacity))
}

.text-black-light{
  --tw-text-opacity:1;
  color:rgba(31, 31, 31, var(--tw-text-opacity))
}

.text-black{
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

.text-error{
  --tw-text-opacity:1;
  color:rgba(255, 49, 49, var(--tw-text-opacity))
}

.text-white{
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.text-beige{
  --tw-text-opacity:1;
  color:rgba(246, 244, 236, var(--tw-text-opacity))
}

.text-gold{
  --tw-text-opacity:1;
  color:rgba(255, 188, 5, var(--tw-text-opacity))
}

.text-brown{
  --tw-text-opacity:1;
  color:rgba(80, 53, 31, var(--tw-text-opacity))
}

.text-gold-dark{
  --tw-text-opacity:1;
  color:rgba(210, 159, 19, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-black{
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

.group:hover .group-hover\:text-white{
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.hover\:text-black-lightest:hover{
  --tw-text-opacity:1;
  color:rgba(76, 75, 74, var(--tw-text-opacity))
}

.hover\:text-black-lighter:hover{
  --tw-text-opacity:1;
  color:rgba(45, 41, 38, var(--tw-text-opacity))
}

.hover\:text-black:hover{
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

.hover\:text-white:hover{
  --tw-text-opacity:1;
  color:rgba(255, 255, 255, var(--tw-text-opacity))
}

.hover\:text-gold:hover{
  --tw-text-opacity:1;
  color:rgba(255, 188, 5, var(--tw-text-opacity))
}

.focus\:text-gold:focus{
  --tw-text-opacity:1;
  color:rgba(255, 188, 5, var(--tw-text-opacity))
}

.text-opacity-30{
  --tw-text-opacity:0.3
}

.text-opacity-70{
  --tw-text-opacity:0.7
}

.underline{
  text-decoration:underline
}

.antialiased{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}

.placeholder-black-50::-moz-placeholder{
  --tw-placeholder-opacity:1;
  color:rgba(217, 217, 217, var(--tw-placeholder-opacity))
}

.placeholder-black-50:-ms-input-placeholder{
  --tw-placeholder-opacity:1;
  color:rgba(217, 217, 217, var(--tw-placeholder-opacity))
}

.placeholder-black-50::placeholder{
  --tw-placeholder-opacity:1;
  color:rgba(217, 217, 217, var(--tw-placeholder-opacity))
}

.opacity-0{
  opacity:0
}

.opacity-15{
  opacity:.15
}

.opacity-20{
  opacity:0.2
}

.opacity-50{
  opacity:0.5
}

.opacity-100{
  opacity:1
}

*, ::before, ::after{
  --tw-shadow:0 0 rgba(0,0,0,0)
}

.shadow-sm{
  --tw-shadow:0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow:0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow)
}

.shadow{
  --tw-shadow:0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow:0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow)
}

.shadow-menu{
  --tw-shadow:0px 4px 4px rgba(0, 0, 0, 0.1);
  box-shadow:0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), var(--tw-shadow);
  box-shadow:var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow)
}

.focus\:outline-none:focus{
  outline:2px solid transparent;
  outline-offset:2px
}

*, ::before, ::after{
  --tw-ring-inset:var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width:0px;
  --tw-ring-offset-color:#fff;
  --tw-ring-color:rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow:0 0 rgba(0,0,0,0);
  --tw-ring-shadow:0 0 rgba(0,0,0,0)
}

.ring-1{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0))
}

.focus\:ring-0:focus{
  --tw-ring-offset-shadow:var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow:var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), 0 0 rgba(0,0,0,0);
  box-shadow:var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0))
}

.ring-gold{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 188, 5, var(--tw-ring-opacity))
}

.focus\:ring-transparent:focus{
  --tw-ring-color:transparent
}

.focus\:ring-gold:focus{
  --tw-ring-opacity:1;
  --tw-ring-color:rgba(255, 188, 5, var(--tw-ring-opacity))
}

.focus\:ring-opacity-0:focus{
  --tw-ring-opacity:0
}

.ring-offset-2{
  --tw-ring-offset-width:2px
}

.focus\:ring-offset-0:focus{
  --tw-ring-offset-width:0px
}

.filter{
  --tw-blur:var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness:var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast:var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale:var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate:var(--tw-empty,/*!*/ /*!*/);
  --tw-invert:var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate:var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia:var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow:var(--tw-empty,/*!*/ /*!*/);
  filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.blur{
  --tw-blur:blur(8px)
}

.transition{
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration:150ms
}

.duration-75{
  transition-duration:75ms
}

.duration-100{
  transition-duration:100ms
}

.duration-150{
  transition-duration:150ms
}

.duration-200{
  transition-duration:200ms
}

.duration-500{
  transition-duration:500ms
}

.duration-1000{
  transition-duration:1000ms
}

.ease-in{
  transition-timing-function:cubic-bezier(0.4, 0, 1, 1)
}

.ease-out{
  transition-timing-function:cubic-bezier(0, 0, 0.2, 1)
}

.ease-in-out{
  transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1)
}

.mask-1{
  -webkit-clip-path:url(#mask0);
  clip-path:url(#mask0)
}

/**
 * Include vendor css.
 *
 */

/**
 * vendor.css
 *
 * All vendor CSS is imported here.
 *
 */

.flatpickr-calendar{
  background:transparent;
  opacity:0;
  display:none;
  text-align:center;
  visibility:hidden;
  padding:0;
  -webkit-animation:none;
  animation:none;
  direction:ltr;
  border:0;
  font-size:0.875rem;
  line-height:1.5rem;
  border-radius:5px;
  position:absolute;
  width:307.875px;
  box-sizing:border-box;
  touch-action:manipulation;
  background:#fff;
  box-shadow:1px 0 0 #e6e6e6,-1px 0 0 #e6e6e6,0 1px 0 #e6e6e6,0 -1px 0 #e6e6e6,0 3px 13px rgba(0,0,0,0.08)
}

.flatpickr-calendar.open,.flatpickr-calendar.inline{
  opacity:1;
  max-height:640px;
  visibility:visible
}

.flatpickr-calendar.open{
  display:inline-block;
  z-index:99999
}

.flatpickr-calendar.animate.open{
  -webkit-animation:fpFadeInDown 300ms cubic-bezier(.23,1,.32,1);
  animation:fpFadeInDown 300ms cubic-bezier(.23,1,.32,1)
}

.flatpickr-calendar.inline{
  display:block;
  position:relative;
  top:2px
}

.flatpickr-calendar.static{
  position:absolute;
  top:calc(100% + 2px)
}

.flatpickr-calendar.static.open{
  z-index:999;
  display:block
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7){
  box-shadow:none !important
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1){
  box-shadow:-2px 0 0 #e6e6e6,5px 0 0 #e6e6e6
}

.flatpickr-calendar .hasWeeks .dayContainer,.flatpickr-calendar .hasTime .dayContainer{
  border-bottom:0;
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}

.flatpickr-calendar .hasWeeks .dayContainer{
  border-left:0
}

.flatpickr-calendar.hasTime .flatpickr-time{
  height:40px;
  border-top:1px solid #e6e6e6
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time{
  height:auto
}

.flatpickr-calendar:before,.flatpickr-calendar:after{
  position:absolute;
  display:block;
  pointer-events:none;
  border:solid transparent;
  content:'';
  height:0;
  width:0;
  left:22px
}

.flatpickr-calendar.rightMost:before,.flatpickr-calendar.arrowRight:before,.flatpickr-calendar.rightMost:after,.flatpickr-calendar.arrowRight:after{
  left:auto;
  right:22px
}

.flatpickr-calendar.arrowCenter:before,.flatpickr-calendar.arrowCenter:after{
  left:50%;
  right:50%
}

.flatpickr-calendar:before{
  border-width:5px;
  margin:0 -5px
}

.flatpickr-calendar:after{
  border-width:4px;
  margin:0 -4px
}

.flatpickr-calendar.arrowTop:before,.flatpickr-calendar.arrowTop:after{
  bottom:100%
}

.flatpickr-calendar.arrowTop:before{
  border-bottom-color:#e6e6e6
}

.flatpickr-calendar.arrowTop:after{
  border-bottom-color:#fff
}

.flatpickr-calendar.arrowBottom:before,.flatpickr-calendar.arrowBottom:after{
  top:100%
}

.flatpickr-calendar.arrowBottom:before{
  border-top-color:#e6e6e6
}

.flatpickr-calendar.arrowBottom:after{
  border-top-color:#fff
}

.flatpickr-calendar:focus{
  outline:0
}

.flatpickr-wrapper{
  position:relative;
  display:inline-block
}

.flatpickr-months{
  display:flex
}

.flatpickr-months .flatpickr-month{
  background:transparent;
  color:rgba(0,0,0,0.9);
  fill:rgba(0,0,0,0.9);
  height:34px;
  line-height:1;
  text-align:center;
  position:relative;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  overflow:hidden;
  flex:1
}

.flatpickr-months .flatpickr-prev-month,.flatpickr-months .flatpickr-next-month{
  text-decoration:none;
  cursor:pointer;
  position:absolute;
  top:0;
  height:34px;
  padding:10px;
  z-index:3;
  color:rgba(0,0,0,0.9);
  fill:rgba(0,0,0,0.9)
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,.flatpickr-months .flatpickr-next-month.flatpickr-disabled{
  display:none
}

.flatpickr-months .flatpickr-prev-month i,.flatpickr-months .flatpickr-next-month i{
  position:relative
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,.flatpickr-months .flatpickr-next-month.flatpickr-prev-month{
  /*
      /*rtl:begin:ignore*/
  left:0
  /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/

/*
      /*rtl:end:ignore*/

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,.flatpickr-months .flatpickr-next-month.flatpickr-next-month{
  /*
      /*rtl:begin:ignore*/
  right:0
  /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/

/*
      /*rtl:end:ignore*/

.flatpickr-months .flatpickr-prev-month:hover,.flatpickr-months .flatpickr-next-month:hover{
  color:#959ea9
}

.flatpickr-months .flatpickr-prev-month:hover svg,.flatpickr-months .flatpickr-next-month:hover svg{
  fill:#f64747
}

.flatpickr-months .flatpickr-prev-month svg,.flatpickr-months .flatpickr-next-month svg{
  width:14px;
  height:14px
}

.flatpickr-months .flatpickr-prev-month svg path,.flatpickr-months .flatpickr-next-month svg path{
  transition:fill .1s;
  fill:inherit
}

.numInputWrapper{
  position:relative;
  height:auto
}

.numInputWrapper input,.numInputWrapper span{
  display:inline-block
}

.numInputWrapper input{
  width:100%
}

.numInputWrapper input::-ms-clear{
  display:none
}

.numInputWrapper input::-webkit-outer-spin-button,.numInputWrapper input::-webkit-inner-spin-button{
  margin:0;
  -webkit-appearance:none
}

.numInputWrapper span{
  position:absolute;
  right:0;
  width:14px;
  padding:0 4px 0 2px;
  height:50%;
  line-height:50%;
  opacity:0;
  cursor:pointer;
  border:1px solid rgba(57,57,57,0.15);
  box-sizing:border-box
}

.numInputWrapper span:hover{
  background:rgba(0,0,0,0.1)
}

.numInputWrapper span:active{
  background:rgba(0,0,0,0.2)
}

.numInputWrapper span:after{
  display:block;
  content:"";
  position:absolute
}

.numInputWrapper span.arrowUp{
  top:0;
  border-bottom:0
}

.numInputWrapper span.arrowUp:after{
  border-left:4px solid transparent;
  border-right:4px solid transparent;
  border-bottom:4px solid rgba(57,57,57,0.6);
  top:26%
}

.numInputWrapper span.arrowDown{
  top:50%
}

.numInputWrapper span.arrowDown:after{
  border-left:4px solid transparent;
  border-right:4px solid transparent;
  border-top:4px solid rgba(57,57,57,0.6);
  top:40%
}

.numInputWrapper span svg{
  width:inherit;
  height:auto
}

.numInputWrapper span svg path{
  fill:rgba(0,0,0,0.5)
}

.numInputWrapper:hover{
  background:rgba(0,0,0,0.05)
}

.numInputWrapper:hover span{
  opacity:1
}

.flatpickr-current-month{
  font-size:135%;
  line-height:inherit;
  font-weight:300;
  color:inherit;
  position:absolute;
  width:75%;
  left:12.5%;
  padding:7.48px 0 0 0;
  line-height:1;
  height:34px;
  display:inline-block;
  text-align:center;
  transform:translate3d(0,0,0)
}

.flatpickr-current-month span.cur-month{
  font-family:inherit;
  font-weight:700;
  color:inherit;
  display:inline-block;
  margin-left:.5ch;
  padding:0
}

.flatpickr-current-month span.cur-month:hover{
  background:rgba(0,0,0,0.05)
}

.flatpickr-current-month .numInputWrapper{
  width:6ch;
  width:7ch\0;
  display:inline-block
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after{
  border-bottom-color:rgba(0,0,0,0.9)
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after{
  border-top-color:rgba(0,0,0,0.9)
}

.flatpickr-current-month input.cur-year{
  background:transparent;
  box-sizing:border-box;
  color:inherit;
  cursor:text;
  padding:0 0 0 .5ch;
  margin:0;
  display:inline-block;
  font-size:inherit;
  font-family:inherit;
  font-weight:300;
  line-height:inherit;
  height:auto;
  border:0;
  border-radius:0;
  vertical-align:baseline;
  vertical-align:initial;
  -webkit-appearance:textfield;
  -moz-appearance:textfield;
  appearance:textfield
}

.flatpickr-current-month input.cur-year:focus{
  outline:0
}

.flatpickr-current-month input.cur-year[disabled],.flatpickr-current-month input.cur-year[disabled]:hover{
  font-size:100%;
  color:rgba(0,0,0,0.5);
  background:transparent;
  pointer-events:none
}

.flatpickr-current-month .flatpickr-monthDropdown-months{
  appearance:menulist;
  background:transparent;
  border:none;
  border-radius:0;
  box-sizing:border-box;
  color:inherit;
  cursor:pointer;
  font-size:inherit;
  font-family:inherit;
  font-weight:300;
  height:auto;
  line-height:inherit;
  margin:-1px 0 0 0;
  outline:none;
  padding:0 0 0 .5ch;
  position:relative;
  vertical-align:baseline;
  vertical-align:initial;
  -webkit-box-sizing:border-box;
  -webkit-appearance:menulist;
  -moz-appearance:menulist;
  width:auto
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,.flatpickr-current-month .flatpickr-monthDropdown-months:active{
  outline:none
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover{
  background:rgba(0,0,0,0.05)
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month{
  background-color:transparent;
  outline:none;
  padding:0
}

.flatpickr-weekdays{
  background:transparent;
  text-align:center;
  overflow:hidden;
  width:100%;
  display:flex;
  align-items:center;
  height:28px
}

.flatpickr-weekdays .flatpickr-weekdaycontainer{
  display:flex;
  flex:1
}

span.flatpickr-weekday{
  cursor:default;
  font-size:90%;
  background:transparent;
  color:rgba(0,0,0,0.54);
  line-height:1;
  margin:0;
  text-align:center;
  display:block;
  flex:1;
  font-weight:bolder
}

.dayContainer,.flatpickr-weeks{
  padding:1px 0 0 0
}

.flatpickr-days{
  position:relative;
  overflow:hidden;
  display:flex;
  align-items:flex-start;
  width:307.875px
}

.flatpickr-days:focus{
  outline:0
}

.dayContainer{
  padding:0;
  outline:0;
  text-align:left;
  width:307.875px;
  min-width:307.875px;
  max-width:307.875px;
  box-sizing:border-box;
  display:inline-block;
  display:flex;
  flex-wrap:wrap;
  -ms-flex-wrap:wrap;
  justify-content:space-around;
  transform:translate3d(0,0,0);
  opacity:1
}

.dayContainer + .dayContainer{
  box-shadow:-1px 0 0 #e6e6e6
}

.flatpickr-day{
  background:none;
  border:1px solid transparent;
  border-radius:150px;
  box-sizing:border-box;
  color:#393939;
  cursor:pointer;
  font-weight:400;
  width:14.2857143%;
  flex-basis:14.2857143%;
  max-width:39px;
  height:39px;
  line-height:2.4375rem;
  margin:0;
  display:inline-block;
  position:relative;
  justify-content:center;
  text-align:center
}

.flatpickr-day.inRange,.flatpickr-day.prevMonthDay.inRange,.flatpickr-day.nextMonthDay.inRange,.flatpickr-day.today.inRange,.flatpickr-day.prevMonthDay.today.inRange,.flatpickr-day.nextMonthDay.today.inRange,.flatpickr-day:hover,.flatpickr-day.prevMonthDay:hover,.flatpickr-day.nextMonthDay:hover,.flatpickr-day:focus,.flatpickr-day.prevMonthDay:focus,.flatpickr-day.nextMonthDay:focus{
  cursor:pointer;
  outline:0;
  background:#e6e6e6;
  border-color:#e6e6e6
}

.flatpickr-day.today{
  border-color:#959ea9
}

.flatpickr-day.today:hover,.flatpickr-day.today:focus{
  border-color:#959ea9;
  background:#959ea9;
  color:#fff
}

.flatpickr-day.selected,.flatpickr-day.startRange,.flatpickr-day.endRange,.flatpickr-day.selected.inRange,.flatpickr-day.startRange.inRange,.flatpickr-day.endRange.inRange,.flatpickr-day.selected:focus,.flatpickr-day.startRange:focus,.flatpickr-day.endRange:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange:hover,.flatpickr-day.endRange:hover,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.endRange.nextMonthDay{
  background:#569ff7;
  box-shadow:none;
  color:#fff;
  border-color:#569ff7
}

.flatpickr-day.selected.startRange,.flatpickr-day.startRange.startRange,.flatpickr-day.endRange.startRange{
  border-radius:50px 0 0 50px
}

.flatpickr-day.selected.endRange,.flatpickr-day.startRange.endRange,.flatpickr-day.endRange.endRange{
  border-radius:0 50px 50px 0
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
  box-shadow:-10px 0 0 #569ff7
}

.flatpickr-day.selected.startRange.endRange,.flatpickr-day.startRange.startRange.endRange,.flatpickr-day.endRange.startRange.endRange{
  border-radius:50px
}

.flatpickr-day.inRange{
  border-radius:0;
  box-shadow:-5px 0 0 #e6e6e6,5px 0 0 #e6e6e6
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover,.flatpickr-day.prevMonthDay,.flatpickr-day.nextMonthDay,.flatpickr-day.notAllowed,.flatpickr-day.notAllowed.prevMonthDay,.flatpickr-day.notAllowed.nextMonthDay{
  color:rgba(57,57,57,0.3);
  background:transparent;
  border-color:transparent;
  cursor:default
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover{
  cursor:not-allowed;
  color:rgba(57,57,57,0.1)
}

.flatpickr-day.week.selected{
  border-radius:0;
  box-shadow:-5px 0 0 #569ff7,5px 0 0 #569ff7
}

.flatpickr-day.hidden{
  visibility:hidden
}

.rangeMode .flatpickr-day{
  margin-top:1px
}

.flatpickr-weekwrapper{
  float:left
}

.flatpickr-weekwrapper .flatpickr-weeks{
  padding:0 12px;
  box-shadow:1px 0 0 #e6e6e6
}

.flatpickr-weekwrapper .flatpickr-weekday{
  float:none;
  width:100%;
  line-height:1.75rem
}

.flatpickr-weekwrapper span.flatpickr-day,.flatpickr-weekwrapper span.flatpickr-day:hover{
  display:block;
  width:100%;
  max-width:none;
  color:rgba(57,57,57,0.3);
  background:transparent;
  cursor:default;
  border:none
}

.flatpickr-innerContainer{
  display:block;
  display:flex;
  box-sizing:border-box;
  overflow:hidden
}

.flatpickr-rContainer{
  display:inline-block;
  padding:0;
  box-sizing:border-box
}

.flatpickr-time{
  text-align:center;
  outline:0;
  display:block;
  height:0;
  line-height:2.5rem;
  max-height:40px;
  box-sizing:border-box;
  overflow:hidden;
  display:flex
}

.flatpickr-time:after{
  content:"";
  display:table;
  clear:both
}

.flatpickr-time .numInputWrapper{
  flex:1;
  width:40%;
  height:40px;
  float:left
}

.flatpickr-time .numInputWrapper span.arrowUp:after{
  border-bottom-color:#393939
}

.flatpickr-time .numInputWrapper span.arrowDown:after{
  border-top-color:#393939
}

.flatpickr-time.hasSeconds .numInputWrapper{
  width:26%
}

.flatpickr-time.time24hr .numInputWrapper{
  width:49%
}

.flatpickr-time input{
  background:transparent;
  box-shadow:none;
  border:0;
  border-radius:0;
  text-align:center;
  margin:0;
  padding:0;
  height:inherit;
  line-height:inherit;
  color:#393939;
  font-size:0.875rem;
  position:relative;
  box-sizing:border-box;
  -webkit-appearance:textfield;
  -moz-appearance:textfield;
  appearance:textfield
}

.flatpickr-time input.flatpickr-hour{
  font-weight:bold
}

.flatpickr-time input.flatpickr-minute,.flatpickr-time input.flatpickr-second{
  font-weight:400
}

.flatpickr-time input:focus{
  outline:0;
  border:0
}

.flatpickr-time .flatpickr-time-separator,.flatpickr-time .flatpickr-am-pm{
  height:inherit;
  float:left;
  line-height:inherit;
  color:#393939;
  font-weight:bold;
  width:2%;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  align-self:center
}

.flatpickr-time .flatpickr-am-pm{
  outline:0;
  width:18%;
  cursor:pointer;
  text-align:center;
  font-weight:400
}

.flatpickr-time input:hover,.flatpickr-time .flatpickr-am-pm:hover,.flatpickr-time input:focus,.flatpickr-time .flatpickr-am-pm:focus{
  background:#eee
}

.flatpickr-input[readonly]{
  cursor:pointer
}

@-webkit-keyframes fpFadeInDown{
  from{
    opacity:0;
    transform:translate3d(0,-20px,0)
  }

  to{
    opacity:1;
    transform:translate3d(0,0,0)
  }
}

@keyframes fpFadeInDown{
  from{
    opacity:0;
    transform:translate3d(0,-20px,0)
  }

  to{
    opacity:1;
    transform:translate3d(0,0,0)
  }
}

@-webkit-keyframes plyr-progress{
  to{
    background-position:25px 0;
    background-position:25px 0;
    background-position:var(--plyr-progress-loading-size,25px) 0
  }
}

@keyframes plyr-progress{
  to{
    background-position:25px 0;
    background-position:25px 0;
    background-position:var(--plyr-progress-loading-size,25px) 0
  }
}

@-webkit-keyframes plyr-popup{
  0%{
    opacity:.5;
    transform:translateY(10px)
  }

  to{
    opacity:1;
    transform:translateY(0)
  }
}

@keyframes plyr-popup{
  0%{
    opacity:.5;
    transform:translateY(10px)
  }

  to{
    opacity:1;
    transform:translateY(0)
  }
}

@-webkit-keyframes plyr-fade-in{
  from{
    opacity:0
  }

  to{
    opacity:1
  }
}

@keyframes plyr-fade-in{
  from{
    opacity:0
  }

  to{
    opacity:1
  }
}

.plyr{
  -moz-osx-font-smoothing:grayscale;
  -webkit-font-smoothing:antialiased;
  align-items:center;
  direction:ltr;
  display:flex;
  flex-direction:column;
  font-family:inherit;
  font-family:inherit;
  font-family:var(--plyr-font-family,inherit);
  font-feature-settings:"tnum";
  font-variant-numeric:tabular-nums;
  font-weight:400;
  font-weight:400;
  font-weight:var(--plyr-font-weight-regular,400);
  line-height:1.7;
  line-height:1.7;
  line-height:var(--plyr-line-height,1.7);
  max-width:100%;
  min-width:200px;
  position:relative;
  text-shadow:none;
  transition:box-shadow .3s ease;
  z-index:0
}

.plyr audio,.plyr iframe,.plyr video{
  display:block;
  height:100%;
  width:100%
}

.plyr button{
  font:inherit;
  line-height:inherit;
  width:auto
}

.plyr:focus{
  outline:0
}

.plyr--full-ui{
  box-sizing:border-box
}

.plyr--full-ui *,.plyr--full-ui ::after,.plyr--full-ui ::before{
  box-sizing:inherit
}

.plyr--full-ui a,.plyr--full-ui button,.plyr--full-ui input,.plyr--full-ui label{
  touch-action:manipulation
}

.plyr__badge{
  background:#4a5464;
  background:#4a5464;
  background:var(--plyr-badge-background,#4a5464);
  border-radius:2px;
  border-radius:2px;
  border-radius:var(--plyr-badge-border-radius,2px);
  color:#fff;
  color:#fff;
  color:var(--plyr-badge-text-color,#fff);
  font-size:0.5625rem;
  font-size:9px;
  font-size:var(--plyr-font-size-badge,9px);
  line-height:1;
  padding:3px 4px
}

.plyr--full-ui ::-webkit-media-text-track-container{
  display:none
}

.plyr__captions{
  -webkit-animation:plyr-fade-in .3s ease;
          animation:plyr-fade-in .3s ease;
  bottom:0;
  display:none;
  font-size:0.8125rem;
  font-size:13px;
  font-size:var(--plyr-font-size-small,13px);
  left:0;
  padding:10px;
  padding:10px;
  padding:var(--plyr-control-spacing,10px);
  position:absolute;
  text-align:center;
  transition:transform .4s ease-in-out;
  width:100%
}

.plyr__captions span:empty{
  display:none
}

@media (min-width:480px){
  .plyr__captions{
    font-size:0.9375rem;
    font-size:15px;
    font-size:var(--plyr-font-size-base,15px);
    padding:calc(10px * 2);
    padding:calc(10px * 2);
    padding:calc(var(--plyr-control-spacing,10px) * 2)
  }
}

@media (min-width:768px){
  .plyr__captions{
    font-size:1.125rem;
    font-size:18px;
    font-size:var(--plyr-font-size-large,18px)
  }
}

.plyr--captions-active .plyr__captions{
  display:block
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty)~.plyr__captions{
  transform:translateY(calc(10px * -4));
  transform:translateY(calc(10px * -4));
  transform:translateY(calc(var(--plyr-control-spacing,10px) * -4))
}

.plyr__caption{
  background:rgba(0,0,0,.8);
  background:rgba(0,0,0,.8);
  background:var(--plyr-captions-background,rgba(0,0,0,.8));
  border-radius:2px;
  -webkit-box-decoration-break:clone;
  box-decoration-break:clone;
  color:#fff;
  color:#fff;
  color:var(--plyr-captions-text-color,#fff);
  line-height:185%;
  padding:.2em .5em;
  white-space:pre-wrap
}

.plyr__caption div{
  display:inline
}

.plyr__control{
  background:0 0;
  border:0;
  border-radius:3px;
  border-radius:3px;
  border-radius:var(--plyr-control-radius,3px);
  color:inherit;
  cursor:pointer;
  flex-shrink:0;
  overflow:visible;
  padding:calc(10px * .7);
  padding:calc(10px * .7);
  padding:calc(var(--plyr-control-spacing,10px) * .7);
  position:relative;
  transition:all .3s ease
}

.plyr__control svg{
  display:block;
  fill:currentColor;
  height:18px;
  height:18px;
  height:var(--plyr-control-icon-size,18px);
  pointer-events:none;
  width:18px;
  width:18px;
  width:var(--plyr-control-icon-size,18px)
}

.plyr__control:focus{
  outline:0
}

.plyr__control.plyr__tab-focus{
  outline-color:#00b3ff;
  outline-color:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  outline-color:var(--plyr-tab-focus-color,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  outline-offset:2px;
  outline-style:dotted;
  outline-width:3px
}

a.plyr__control{
  text-decoration:none
}

a.plyr__control::after,a.plyr__control::before{
  display:none
}

.plyr__control.plyr__control--pressed .icon--not-pressed,.plyr__control.plyr__control--pressed .label--not-pressed,.plyr__control:not(.plyr__control--pressed) .icon--pressed,.plyr__control:not(.plyr__control--pressed) .label--pressed{
  display:none
}

.plyr--full-ui ::-webkit-media-controls{
  display:none
}

.plyr__controls{
  align-items:center;
  display:flex;
  justify-content:flex-end;
  text-align:center
}

.plyr__controls .plyr__progress__container{
  flex:1;
  min-width:0
}

.plyr__controls .plyr__controls__item{
  margin-left:calc(10px / 4);
  margin-left:calc(10px/ 4);
  margin-left:calc(var(--plyr-control-spacing,10px)/ 4)
}

.plyr__controls .plyr__controls__item:first-child{
  margin-left:0;
  margin-right:auto
}

.plyr__controls .plyr__controls__item.plyr__progress__container{
  padding-left:calc(10px / 4);
  padding-left:calc(10px/ 4);
  padding-left:calc(var(--plyr-control-spacing,10px)/ 4)
}

.plyr__controls .plyr__controls__item.plyr__time{
  padding:0 calc(10px / 2);
  padding:0 calc(10px/ 2);
  padding:0 calc(var(--plyr-control-spacing,10px)/ 2)
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child,.plyr__controls .plyr__controls__item.plyr__time+.plyr__time,.plyr__controls .plyr__controls__item.plyr__time:first-child{
  padding-left:0
}

.plyr__controls:empty{
  display:none
}

.plyr [data-plyr=airplay],.plyr [data-plyr=captions],.plyr [data-plyr=fullscreen],.plyr [data-plyr=pip]{
  display:none
}

.plyr--airplay-supported [data-plyr=airplay],.plyr--captions-enabled [data-plyr=captions],.plyr--fullscreen-enabled [data-plyr=fullscreen],.plyr--pip-supported [data-plyr=pip]{
  display:inline-block
}

.plyr__menu{
  display:flex;
  position:relative
}

.plyr__menu .plyr__control svg{
  transition:transform .3s ease
}

.plyr__menu .plyr__control[aria-expanded=true] svg{
  transform:rotate(90deg)
}

.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip{
  display:none
}

.plyr__menu__container{
  -webkit-animation:plyr-popup .2s ease;
          animation:plyr-popup .2s ease;
  background:rgba(255,255,255,.9);
  background:rgba(255,255,255,.9);
  background:var(--plyr-menu-background,rgba(255,255,255,.9));
  border-radius:4px;
  bottom:100%;
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  box-shadow:var(--plyr-menu-shadow,0 1px 2px rgba(0,0,0,.15));
  color:#4a5464;
  color:#4a5464;
  color:var(--plyr-menu-color,#4a5464);
  font-size:0.9375rem;
  font-size:15px;
  font-size:var(--plyr-font-size-base,15px);
  margin-bottom:10px;
  position:absolute;
  right:-3px;
  text-align:left;
  white-space:nowrap;
  z-index:3
}

.plyr__menu__container>div{
  overflow:hidden;
  transition:height .35s cubic-bezier(.4,0,.2,1),width .35s cubic-bezier(.4,0,.2,1)
}

.plyr__menu__container::after{
  border:4px solid transparent;
  border:4px solid transparent;
  border:var(--plyr-menu-arrow-size,4px) solid transparent;
  border-top-color:rgba(255,255,255,.9);
  border-top-color:rgba(255,255,255,.9);
  border-top-color:var(--plyr-menu-background,rgba(255,255,255,.9));
  content:'';
  height:0;
  position:absolute;
  right:calc(((18px / 2) + calc(10px * .7)) - (4px / 2));
  right:calc(((18px/ 2) + calc(10px * .7)) - (4px/ 2));
  right:calc(((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7)) - (var(--plyr-menu-arrow-size,4px)/ 2));
  top:100%;
  width:0
}

.plyr__menu__container [role=menu]{
  padding:calc(10px * .7);
  padding:calc(10px * .7);
  padding:calc(var(--plyr-control-spacing,10px) * .7)
}

.plyr__menu__container [role=menuitem],.plyr__menu__container [role=menuitemradio]{
  margin-top:2px
}

.plyr__menu__container [role=menuitem]:first-child,.plyr__menu__container [role=menuitemradio]:first-child{
  margin-top:0
}

.plyr__menu__container .plyr__control{
  align-items:center;
  color:#4a5464;
  color:#4a5464;
  color:var(--plyr-menu-color,#4a5464);
  display:flex;
  font-size:0.8125rem;
  font-size:13px;
  font-size:var(--plyr-font-size-menu,var(--plyr-font-size-small,13px));
  padding-bottom:calc(calc(10px * .7)/ 1.5);
  padding-bottom:calc(calc(10px * .7)/ 1.5);
  padding-bottom:calc(calc(var(--plyr-control-spacing,10px) * .7)/ 1.5);
  padding-left:calc(calc(10px * .7) * 1.5);
  padding-left:calc(calc(10px * .7) * 1.5);
  padding-left:calc(calc(var(--plyr-control-spacing,10px) * .7) * 1.5);
  padding-right:calc(calc(10px * .7) * 1.5);
  padding-right:calc(calc(10px * .7) * 1.5);
  padding-right:calc(calc(var(--plyr-control-spacing,10px) * .7) * 1.5);
  padding-top:calc(calc(10px * .7)/ 1.5);
  padding-top:calc(calc(10px * .7)/ 1.5);
  padding-top:calc(calc(var(--plyr-control-spacing,10px) * .7)/ 1.5);
  -webkit-user-select:none;
  -ms-user-select:none;
  -moz-user-select:none;
       user-select:none;
  width:100%
}

.plyr__menu__container .plyr__control>span{
  align-items:inherit;
  display:flex;
  width:100%
}

.plyr__menu__container .plyr__control::after{
  border:4px solid transparent;
  border:4px solid transparent;
  border:var(--plyr-menu-item-arrow-size,4px) solid transparent;
  content:'';
  position:absolute;
  top:50%;
  transform:translateY(-50%)
}

.plyr__menu__container .plyr__control--forward{
  padding-right:calc(calc(10px * .7) * 4);
  padding-right:calc(calc(10px * .7) * 4);
  padding-right:calc(calc(var(--plyr-control-spacing,10px) * .7) * 4)
}

.plyr__menu__container .plyr__control--forward::after{
  border-left-color:#728197;
  border-left-color:#728197;
  border-left-color:var(--plyr-menu-arrow-color,#728197);
  right:calc((calc(10px * .7) * 1.5) - 4px);
  right:calc((calc(10px * .7) * 1.5) - 4px);
  right:calc((calc(var(--plyr-control-spacing,10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size,4px))
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after,.plyr__menu__container .plyr__control--forward:hover::after{
  border-left-color:currentColor
}

.plyr__menu__container .plyr__control--back{
  font-weight:400;
  font-weight:400;
  font-weight:var(--plyr-font-weight-regular,400);
  margin:calc(10px * .7);
  margin:calc(10px * .7);
  margin:calc(var(--plyr-control-spacing,10px) * .7);
  margin-bottom:calc(calc(10px * .7)/ 2);
  margin-bottom:calc(calc(10px * .7)/ 2);
  margin-bottom:calc(calc(var(--plyr-control-spacing,10px) * .7)/ 2);
  padding-left:calc(calc(10px * .7) * 4);
  padding-left:calc(calc(10px * .7) * 4);
  padding-left:calc(calc(var(--plyr-control-spacing,10px) * .7) * 4);
  position:relative;
  width:calc(100% - (calc(10px * .7) * 2));
  width:calc(100% - (calc(10px * .7) * 2));
  width:calc(100% - (calc(var(--plyr-control-spacing,10px) * .7) * 2))
}

.plyr__menu__container .plyr__control--back::after{
  border-right-color:#728197;
  border-right-color:#728197;
  border-right-color:var(--plyr-menu-arrow-color,#728197);
  left:calc((calc(10px * .7) * 1.5) - 4px);
  left:calc((calc(10px * .7) * 1.5) - 4px);
  left:calc((calc(var(--plyr-control-spacing,10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size,4px))
}

.plyr__menu__container .plyr__control--back::before{
  background:#dcdfe5;
  background:#dcdfe5;
  background:var(--plyr-menu-back-border-color,#dcdfe5);
  box-shadow:0 1px 0 #fff;
  box-shadow:0 1px 0 #fff;
  box-shadow:0 1px 0 var(--plyr-menu-back-border-shadow-color,#fff);
  content:'';
  height:1px;
  left:0;
  margin-top:calc(calc(10px * .7)/ 2);
  margin-top:calc(calc(10px * .7)/ 2);
  margin-top:calc(calc(var(--plyr-control-spacing,10px) * .7)/ 2);
  overflow:hidden;
  position:absolute;
  right:0;
  top:100%
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus::after,.plyr__menu__container .plyr__control--back:hover::after{
  border-right-color:currentColor
}

.plyr__menu__container .plyr__control[role=menuitemradio]{
  padding-left:calc(10px * .7);
  padding-left:calc(10px * .7);
  padding-left:calc(var(--plyr-control-spacing,10px) * .7)
}

.plyr__menu__container .plyr__control[role=menuitemradio]::after,.plyr__menu__container .plyr__control[role=menuitemradio]::before{
  border-radius:100%
}

.plyr__menu__container .plyr__control[role=menuitemradio]::before{
  background:rgba(0,0,0,.1);
  content:'';
  display:block;
  flex-shrink:0;
  height:16px;
  margin-right:10px;
  margin-right:10px;
  margin-right:var(--plyr-control-spacing,10px);
  transition:all .3s ease;
  width:16px
}

.plyr__menu__container .plyr__control[role=menuitemradio]::after{
  background:#fff;
  border:0;
  height:6px;
  left:12px;
  opacity:0;
  top:50%;
  transform:translateY(-50%) scale(0);
  transition:transform .3s ease,opacity .3s ease;
  width:6px
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before{
  background:#00b3ff;
  background:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  background:var(--plyr-control-toggle-checked-background,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)))
}

.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after{
  opacity:1;
  transform:translateY(-50%) scale(1)
}

.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before,.plyr__menu__container .plyr__control[role=menuitemradio]:hover::before{
  background:rgba(35,40,47,.1)
}

.plyr__menu__container .plyr__menu__value{
  align-items:center;
  display:flex;
  margin-left:auto;
  margin-right:calc((calc(10px * .7) - 2) * -1);
  margin-right:calc((calc(10px * .7) - 2) * -1);
  margin-right:calc((calc(var(--plyr-control-spacing,10px) * .7) - 2) * -1);
  overflow:hidden;
  padding-left:calc(calc(10px * .7) * 3.5);
  padding-left:calc(calc(10px * .7) * 3.5);
  padding-left:calc(calc(var(--plyr-control-spacing,10px) * .7) * 3.5);
  pointer-events:none
}

.plyr--full-ui input[type=range]{
  -webkit-appearance:none;
  background:0 0;
  border:0;
  border-radius:calc(13px * 2);
  border-radius:calc(13px * 2);
  border-radius:calc(var(--plyr-range-thumb-height,13px) * 2);
  color:#00b3ff;
  color:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  color:var(--plyr-range-fill-background,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  display:block;
  height:calc((3px * 2) + 13px);
  height:calc((3px * 2) + 13px);
  height:calc((var(--plyr-range-thumb-active-shadow-width,3px) * 2) + var(--plyr-range-thumb-height,13px));
  margin:0;
  min-width:0;
  padding:0;
  transition:box-shadow .3s ease;
  width:100%
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track{
  background:0 0;
  border:0;
  border-radius:calc(5px / 2);
  border-radius:calc(5px/ 2);
  border-radius:calc(var(--plyr-range-track-height,5px)/ 2);
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  -webkit-transition:box-shadow .3s ease;
  transition:box-shadow .3s ease;
  -webkit-user-select:none;
  user-select:none;
  background-image:linear-gradient(to right,currentColor 0,transparent 0);
  background-image:linear-gradient(to right,currentColor 0,transparent 0);
  background-image:linear-gradient(to right,currentColor var(--value,0),transparent var(--value,0))
}

.plyr--full-ui input[type=range]::-webkit-slider-thumb{
  background:#fff;
  background:#fff;
  background:var(--plyr-range-thumb-background,#fff);
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2));
  height:13px;
  height:13px;
  height:var(--plyr-range-thumb-height,13px);
  position:relative;
  -webkit-transition:all .2s ease;
  transition:all .2s ease;
  width:13px;
  width:13px;
  width:var(--plyr-range-thumb-height,13px);
  -webkit-appearance:none;
  margin-top:calc(((13px - 5px)/ 2) * -1);
  margin-top:calc(((13px - 5px)/ 2) * -1);
  margin-top:calc(((var(--plyr-range-thumb-height,13px) - var(--plyr-range-track-height,5px))/ 2) * -1)
}

.plyr--full-ui input[type=range]::-moz-range-track{
  background:0 0;
  border:0;
  border-radius:calc(5px / 2);
  border-radius:calc(5px/ 2);
  border-radius:calc(var(--plyr-range-track-height,5px)/ 2);
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  -moz-transition:box-shadow .3s ease;
  transition:box-shadow .3s ease;
  -moz-user-select:none;
       user-select:none
}

.plyr--full-ui input[type=range]::-moz-range-thumb{
  background:#fff;
  background:#fff;
  background:var(--plyr-range-thumb-background,#fff);
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2));
  height:13px;
  height:13px;
  height:var(--plyr-range-thumb-height,13px);
  position:relative;
  -moz-transition:all .2s ease;
  transition:all .2s ease;
  width:13px;
  width:13px;
  width:var(--plyr-range-thumb-height,13px)
}

.plyr--full-ui input[type=range]::-moz-range-progress{
  background:currentColor;
  border-radius:calc(5px / 2);
  border-radius:calc(5px/ 2);
  border-radius:calc(var(--plyr-range-track-height,5px)/ 2);
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px)
}

.plyr--full-ui input[type=range]::-ms-track{
  background:0 0;
  border:0;
  border-radius:calc(5px / 2);
  border-radius:calc(5px/ 2);
  border-radius:calc(var(--plyr-range-track-height,5px)/ 2);
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  -ms-transition:box-shadow .3s ease;
  transition:box-shadow .3s ease;
  -ms-user-select:none;
  user-select:none;
  color:transparent
}

.plyr--full-ui input[type=range]::-ms-fill-upper{
  background:0 0;
  border:0;
  border-radius:calc(5px / 2);
  border-radius:calc(5px/ 2);
  border-radius:calc(var(--plyr-range-track-height,5px)/ 2);
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  -ms-transition:box-shadow .3s ease;
  transition:box-shadow .3s ease;
  -ms-user-select:none;
  user-select:none
}

.plyr--full-ui input[type=range]::-ms-fill-lower{
  background:0 0;
  border:0;
  border-radius:calc(5px / 2);
  border-radius:calc(5px/ 2);
  border-radius:calc(var(--plyr-range-track-height,5px)/ 2);
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  -ms-transition:box-shadow .3s ease;
  transition:box-shadow .3s ease;
  -ms-user-select:none;
  user-select:none;
  background:currentColor
}

.plyr--full-ui input[type=range]::-ms-thumb{
  background:#fff;
  background:#fff;
  background:var(--plyr-range-thumb-background,#fff);
  border:0;
  border-radius:100%;
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2));
  height:13px;
  height:13px;
  height:var(--plyr-range-thumb-height,13px);
  position:relative;
  -ms-transition:all .2s ease;
  transition:all .2s ease;
  width:13px;
  width:13px;
  width:var(--plyr-range-thumb-height,13px);
  margin-top:0
}

.plyr--full-ui input[type=range]::-ms-tooltip{
  display:none
}

.plyr--full-ui input[type=range]:focus{
  outline:0
}

.plyr--full-ui input[type=range]::-moz-focus-outer{
  border:0
}

.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track{
  outline-color:#00b3ff;
  outline-color:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  outline-color:var(--plyr-tab-focus-color,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  outline-offset:2px;
  outline-style:dotted;
  outline-width:3px
}

.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track{
  outline-color:#00b3ff;
  outline-color:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  outline-color:var(--plyr-tab-focus-color,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  outline-offset:2px;
  outline-style:dotted;
  outline-width:3px
}

.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track{
  outline-color:#00b3ff;
  outline-color:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  outline-color:var(--plyr-tab-focus-color,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  outline-offset:2px;
  outline-style:dotted;
  outline-width:3px
}

.plyr__poster{
  background-color:#000;
  background-color:#000;
  background-color:var(--plyr-video-background,var(--plyr-video-background,#000));
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size:contain;
  height:100%;
  left:0;
  opacity:0;
  position:absolute;
  top:0;
  transition:opacity .2s ease;
  width:100%;
  z-index:1
}

.plyr--stopped.plyr__poster-enabled .plyr__poster{
  opacity:1
}

.plyr__time{
  font-size:0.8125rem;
  font-size:13px;
  font-size:var(--plyr-font-size-time,var(--plyr-font-size-small,13px))
}

.plyr__time+.plyr__time::before{
  content:'\2044';
  margin-right:10px;
  margin-right:10px;
  margin-right:var(--plyr-control-spacing,10px)
}

@media (max-width:767px){
  .plyr__time+.plyr__time{
    display:none
  }
}

.plyr__tooltip{
  background:rgba(255,255,255,.9);
  background:rgba(255,255,255,.9);
  background:var(--plyr-tooltip-background,rgba(255,255,255,.9));
  border-radius:3px;
  border-radius:3px;
  border-radius:var(--plyr-tooltip-radius,3px);
  bottom:100%;
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  box-shadow:var(--plyr-tooltip-shadow,0 1px 2px rgba(0,0,0,.15));
  color:#4a5464;
  color:#4a5464;
  color:var(--plyr-tooltip-color,#4a5464);
  font-size:0.8125rem;
  font-size:13px;
  font-size:var(--plyr-font-size-small,13px);
  font-weight:400;
  font-weight:400;
  font-weight:var(--plyr-font-weight-regular,400);
  left:50%;
  line-height:1.3;
  margin-bottom:calc(calc(10px / 2) * 2);
  margin-bottom:calc(calc(10px/ 2) * 2);
  margin-bottom:calc(calc(var(--plyr-control-spacing,10px)/ 2) * 2);
  opacity:0;
  padding:calc(10px / 2) calc(calc(10px / 2) * 1.5);
  padding:calc(10px/ 2) calc(calc(10px/ 2) * 1.5);
  padding:calc(var(--plyr-control-spacing,10px)/ 2) calc(calc(var(--plyr-control-spacing,10px)/ 2) * 1.5);
  pointer-events:none;
  position:absolute;
  transform:translate(-50%,10px) scale(.8);
  transform-origin:50% 100%;
  transition:transform .2s .1s ease,opacity .2s .1s ease;
  white-space:nowrap;
  z-index:2
}

.plyr__tooltip::before{
  border-left:4px solid transparent;
  border-left:4px solid transparent;
  border-left:var(--plyr-tooltip-arrow-size,4px) solid transparent;
  border-right:4px solid transparent;
  border-right:4px solid transparent;
  border-right:var(--plyr-tooltip-arrow-size,4px) solid transparent;
  border-top:4px solid rgba(255,255,255,.9);
  border-top:4px solid rgba(255,255,255,.9);
  border-top:var(--plyr-tooltip-arrow-size,4px) solid var(--plyr-tooltip-background,rgba(255,255,255,.9));
  bottom:calc(4px * -1);
  bottom:calc(4px * -1);
  bottom:calc(var(--plyr-tooltip-arrow-size,4px) * -1);
  content:'';
  height:0;
  left:50%;
  position:absolute;
  transform:translateX(-50%);
  width:0;
  z-index:2
}

.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,.plyr .plyr__control:hover .plyr__tooltip,.plyr__tooltip--visible{
  opacity:1;
  transform:translate(-50%,0) scale(1)
}

.plyr .plyr__control:hover .plyr__tooltip{
  z-index:3
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip{
  left:0;
  transform:translate(0,10px) scale(.8);
  transform-origin:0 100%
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip::before,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip::before{
  left:calc((18px / 2) + calc(10px * .7));
  left:calc((18px/ 2) + calc(10px * .7));
  left:calc((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7))
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip{
  left:auto;
  right:0;
  transform:translate(0,10px) scale(.8);
  transform-origin:100% 100%
}

.plyr__controls>.plyr__control:last-child .plyr__tooltip::before{
  left:auto;
  right:calc((18px / 2) + calc(10px * .7));
  right:calc((18px/ 2) + calc(10px * .7));
  right:calc((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7));
  transform:translateX(50%)
}

.plyr__controls>.plyr__control:first-child .plyr__tooltip--visible,.plyr__controls>.plyr__control:first-child+.plyr__control .plyr__tooltip--visible,.plyr__controls>.plyr__control:first-child+.plyr__control.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:first-child+.plyr__control:hover .plyr__tooltip,.plyr__controls>.plyr__control:first-child.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:first-child:hover .plyr__tooltip,.plyr__controls>.plyr__control:last-child .plyr__tooltip--visible,.plyr__controls>.plyr__control:last-child.plyr__tab-focus .plyr__tooltip,.plyr__controls>.plyr__control:last-child:hover .plyr__tooltip{
  transform:translate(0,0) scale(1)
}

.plyr__progress{
  left:calc(13px * .5);
  left:calc(13px * .5);
  left:calc(var(--plyr-range-thumb-height,13px) * .5);
  margin-right:13px;
  margin-right:13px;
  margin-right:var(--plyr-range-thumb-height,13px);
  position:relative
}

.plyr__progress input[type=range],.plyr__progress__buffer{
  margin-left:calc(13px * -.5);
  margin-left:calc(13px * -.5);
  margin-left:calc(var(--plyr-range-thumb-height,13px) * -.5);
  margin-right:calc(13px * -.5);
  margin-right:calc(13px * -.5);
  margin-right:calc(var(--plyr-range-thumb-height,13px) * -.5);
  width:calc(100% + 13px);
  width:calc(100% + 13px);
  width:calc(100% + var(--plyr-range-thumb-height,13px))
}

.plyr__progress input[type=range]{
  position:relative;
  z-index:2
}

.plyr__progress .plyr__tooltip{
  font-size:0.8125rem;
  font-size:13px;
  font-size:var(--plyr-font-size-time,var(--plyr-font-size-small,13px));
  left:0
}

.plyr__progress__buffer{
  -webkit-appearance:none;
  background:0 0;
  border:0;
  border-radius:100px;
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  left:0;
  margin-top:calc((5px / 2) * -1);
  margin-top:calc((5px/ 2) * -1);
  margin-top:calc((var(--plyr-range-track-height,5px)/ 2) * -1);
  padding:0;
  position:absolute;
  top:50%
}

.plyr__progress__buffer::-webkit-progress-bar{
  background:0 0
}

.plyr__progress__buffer::-webkit-progress-value{
  background:currentColor;
  border-radius:100px;
  min-width:5px;
  min-width:5px;
  min-width:var(--plyr-range-track-height,5px);
  -webkit-transition:width .2s ease;
  transition:width .2s ease
}

.plyr__progress__buffer::-moz-progress-bar{
  background:currentColor;
  border-radius:100px;
  min-width:5px;
  min-width:5px;
  min-width:var(--plyr-range-track-height,5px);
  -moz-transition:width .2s ease;
  transition:width .2s ease
}

.plyr__progress__buffer::-ms-fill{
  border-radius:100px;
  -ms-transition:width .2s ease;
  transition:width .2s ease
}

.plyr--loading .plyr__progress__buffer{
  -webkit-animation:plyr-progress 1s linear infinite;
          animation:plyr-progress 1s linear infinite;
  background-image:linear-gradient(-45deg,rgba(35,40,47,.6) 25%,transparent 25%,transparent 50%,rgba(35,40,47,.6) 50%,rgba(35,40,47,.6) 75%,transparent 75%,transparent);
  background-image:linear-gradient(-45deg,rgba(35,40,47,.6) 25%,transparent 25%,transparent 50%,rgba(35,40,47,.6) 50%,rgba(35,40,47,.6) 75%,transparent 75%,transparent);
  background-image:linear-gradient(-45deg,var(--plyr-progress-loading-background,rgba(35,40,47,.6)) 25%,transparent 25%,transparent 50%,var(--plyr-progress-loading-background,rgba(35,40,47,.6)) 50%,var(--plyr-progress-loading-background,rgba(35,40,47,.6)) 75%,transparent 75%,transparent);
  background-repeat:repeat-x;
  background-size:25px 25px;
  background-size:25px 25px;
  background-size:var(--plyr-progress-loading-size,25px) var(--plyr-progress-loading-size,25px);
  color:transparent
}

.plyr--video.plyr--loading .plyr__progress__buffer{
  background-color:rgba(255,255,255,.25);
  background-color:rgba(255,255,255,.25);
  background-color:var(--plyr-video-progress-buffered-background,rgba(255,255,255,.25))
}

.plyr--audio.plyr--loading .plyr__progress__buffer{
  background-color:rgba(193,200,209,.6);
  background-color:rgba(193,200,209,.6);
  background-color:var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6))
}

.plyr__volume{
  align-items:center;
  display:flex;
  max-width:110px;
  min-width:80px;
  position:relative;
  width:20%
}

.plyr__volume input[type=range]{
  margin-left:calc(10px / 2);
  margin-left:calc(10px/ 2);
  margin-left:calc(var(--plyr-control-spacing,10px)/ 2);
  margin-right:calc(10px / 2);
  margin-right:calc(10px/ 2);
  margin-right:calc(var(--plyr-control-spacing,10px)/ 2);
  position:relative;
  z-index:2
}

.plyr--is-ios .plyr__volume{
  min-width:0;
  width:auto
}

.plyr--audio{
  display:block
}

.plyr--audio .plyr__controls{
  background:#fff;
  background:#fff;
  background:var(--plyr-audio-controls-background,#fff);
  border-radius:inherit;
  color:#4a5464;
  color:#4a5464;
  color:var(--plyr-audio-control-color,#4a5464);
  padding:10px;
  padding:10px;
  padding:var(--plyr-control-spacing,10px)
}

.plyr--audio .plyr__control.plyr__tab-focus,.plyr--audio .plyr__control:hover,.plyr--audio .plyr__control[aria-expanded=true]{
  background:#00b3ff;
  background:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  background:var(--plyr-audio-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  color:#fff;
  color:#fff;
  color:var(--plyr-audio-control-color-hover,#fff)
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track{
  background-color:rgba(193,200,209,.6);
  background-color:rgba(193,200,209,.6);
  background-color:var(--plyr-audio-range-track-background,var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6)))
}

.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track{
  background-color:rgba(193,200,209,.6);
  background-color:rgba(193,200,209,.6);
  background-color:var(--plyr-audio-range-track-background,var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6)))
}

.plyr--full-ui.plyr--audio input[type=range]::-ms-track{
  background-color:rgba(193,200,209,.6);
  background-color:rgba(193,200,209,.6);
  background-color:var(--plyr-audio-range-track-background,var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6)))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb{
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(35,40,47,.1))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb{
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(35,40,47,.1))
}

.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb{
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(35,40,47,.1);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(35,40,47,.1))
}

.plyr--audio .plyr__progress__buffer{
  color:rgba(193,200,209,.6);
  color:rgba(193,200,209,.6);
  color:var(--plyr-audio-progress-buffered-background,rgba(193,200,209,.6))
}

.plyr--video{
  background:#000;
  background:#000;
  background:var(--plyr-video-background,var(--plyr-video-background,#000));
  overflow:hidden
}

.plyr--video.plyr--menu-open{
  overflow:visible
}

.plyr__video-wrapper{
  background:#000;
  background:#000;
  background:var(--plyr-video-background,var(--plyr-video-background,#000));
  height:100%;
  margin:auto;
  overflow:hidden;
  position:relative;
  width:100%
}

.plyr__video-embed,.plyr__video-wrapper--fixed-ratio{
  height:0;
  padding-bottom:56.25%
}

.plyr__video-embed iframe,.plyr__video-wrapper--fixed-ratio video{
  border:0;
  left:0;
  position:absolute;
  top:0
}

.plyr--full-ui .plyr__video-embed>.plyr__video-embed__container{
  padding-bottom:240%;
  position:relative;
  transform:translateY(-38.28125%)
}

.plyr--video .plyr__controls{
  background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.75));
  background:linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.75));
  background:var(--plyr-video-controls-background,linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.75)));
  border-bottom-left-radius:inherit;
  border-bottom-right-radius:inherit;
  bottom:0;
  color:#fff;
  color:#fff;
  color:var(--plyr-video-control-color,#fff);
  left:0;
  padding:calc(10px / 2);
  padding:calc(10px/ 2);
  padding:calc(var(--plyr-control-spacing,10px)/ 2);
  padding-top:calc(10px * 2);
  padding-top:calc(10px * 2);
  padding-top:calc(var(--plyr-control-spacing,10px) * 2);
  position:absolute;
  right:0;
  transition:opacity .4s ease-in-out,transform .4s ease-in-out;
  z-index:3
}

@media (min-width:480px){
  .plyr--video .plyr__controls{
    padding:10px;
    padding:10px;
    padding:var(--plyr-control-spacing,10px);
    padding-top:calc(10px * 3.5);
    padding-top:calc(10px * 3.5);
    padding-top:calc(var(--plyr-control-spacing,10px) * 3.5)
  }
}

.plyr--video.plyr--hide-controls .plyr__controls{
  opacity:0;
  pointer-events:none;
  transform:translateY(100%)
}

.plyr--video .plyr__control.plyr__tab-focus,.plyr--video .plyr__control:hover,.plyr--video .plyr__control[aria-expanded=true]{
  background:#00b3ff;
  background:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  background:var(--plyr-video-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  color:#fff;
  color:#fff;
  color:var(--plyr-video-control-color-hover,#fff)
}

.plyr__control--overlaid{
  background:#00b3ff;
  background:var(--plyr-color-main,var(--plyr-color-main,#00b3ff));
  background:var(--plyr-video-control-background-hover,var(--plyr-color-main,var(--plyr-color-main,#00b3ff)));
  border:0;
  border-radius:100%;
  color:#fff;
  color:#fff;
  color:var(--plyr-video-control-color,#fff);
  display:none;
  left:50%;
  opacity:.9;
  padding:calc(10px * 1.5);
  padding:calc(10px * 1.5);
  padding:calc(var(--plyr-control-spacing,10px) * 1.5);
  position:absolute;
  top:50%;
  transform:translate(-50%,-50%);
  transition:.3s;
  z-index:2
}

.plyr__control--overlaid svg{
  left:2px;
  position:relative
}

.plyr__control--overlaid:focus,.plyr__control--overlaid:hover{
  opacity:1
}

.plyr--playing .plyr__control--overlaid{
  opacity:0;
  visibility:hidden
}

.plyr--full-ui.plyr--video .plyr__control--overlaid{
  display:block
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track{
  background-color:rgba(255,255,255,.25);
  background-color:rgba(255,255,255,.25);
  background-color:var(--plyr-video-range-track-background,var(--plyr-video-progress-buffered-background,rgba(255,255,255,.25)))
}

.plyr--full-ui.plyr--video input[type=range]::-moz-range-track{
  background-color:rgba(255,255,255,.25);
  background-color:rgba(255,255,255,.25);
  background-color:var(--plyr-video-range-track-background,var(--plyr-video-progress-buffered-background,rgba(255,255,255,.25)))
}

.plyr--full-ui.plyr--video input[type=range]::-ms-track{
  background-color:rgba(255,255,255,.25);
  background-color:rgba(255,255,255,.25);
  background-color:var(--plyr-video-range-track-background,var(--plyr-video-progress-buffered-background,rgba(255,255,255,.25)))
}

.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb{
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(255,255,255,.5))
}

.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb{
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(255,255,255,.5))
}

.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb{
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
  box-shadow:0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2),0 0 0 3px rgba(255,255,255,.5);
  box-shadow:var(--plyr-range-thumb-shadow,0 1px 1px rgba(35,40,47,.15),0 0 0 1px rgba(35,40,47,.2)),0 0 0 var(--plyr-range-thumb-active-shadow-width,3px) var(--plyr-audio-range-thumb-active-shadow-color,rgba(255,255,255,.5))
}

.plyr--video .plyr__progress__buffer{
  color:rgba(255,255,255,.25);
  color:rgba(255,255,255,.25);
  color:var(--plyr-video-progress-buffered-background,rgba(255,255,255,.25))
}

.plyr:-webkit-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}

.plyr:-ms-fullscreen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}

.plyr:fullscreen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}

.plyr:-webkit-full-screen video{
  height:100%
}

.plyr:-ms-fullscreen video{
  height:100%
}

.plyr:fullscreen video{
  height:100%
}

.plyr:-webkit-full-screen .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr:-ms-fullscreen .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr:fullscreen .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr:fullscreen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr:-webkit-full-screen.plyr--hide-controls{
  cursor:none
}

.plyr:-ms-fullscreen.plyr--hide-controls{
  cursor:none
}

.plyr:fullscreen.plyr--hide-controls{
  cursor:none
}

@media (min-width:1024px){
  .plyr:-webkit-full-screen .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }

  .plyr:-ms-fullscreen .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }

  .plyr:fullscreen .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }
}

.plyr:-webkit-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}

.plyr:-webkit-full-screen video{
  height:100%
}

.plyr:-webkit-full-screen .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr:-webkit-full-screen.plyr--hide-controls{
  cursor:none
}

@media (min-width:1024px){
  .plyr:-webkit-full-screen .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }
}

.plyr:-moz-full-screen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}

.plyr:-moz-full-screen video{
  height:100%
}

.plyr:-moz-full-screen .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr:-moz-full-screen.plyr--hide-controls{
  cursor:none
}

@media (min-width:1024px){
  .plyr:-moz-full-screen .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }
}

.plyr:-ms-fullscreen{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%
}

.plyr:-ms-fullscreen video{
  height:100%
}

.plyr:-ms-fullscreen .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr:-ms-fullscreen.plyr--hide-controls{
  cursor:none
}

@media (min-width:1024px){
  .plyr:-ms-fullscreen .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }
}

.plyr--fullscreen-fallback{
  background:#000;
  border-radius:0!important;
  height:100%;
  margin:0;
  width:100%;
  bottom:0;
  display:block;
  left:0;
  position:fixed;
  right:0;
  top:0;
  z-index:10000000
}

.plyr--fullscreen-fallback video{
  height:100%
}

.plyr--fullscreen-fallback .plyr__video-wrapper{
  height:100%;
  position:static
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper{
  height:0;
  position:relative
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen{
  display:block
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen+svg{
  display:none
}

.plyr--fullscreen-fallback.plyr--hide-controls{
  cursor:none
}

@media (min-width:1024px){
  .plyr--fullscreen-fallback .plyr__captions{
    font-size:1.3125rem;
    font-size:21px;
    font-size:var(--plyr-font-size-xlarge,21px)
  }
}

.plyr__ads{
  border-radius:inherit;
  bottom:0;
  cursor:pointer;
  left:0;
  overflow:hidden;
  position:absolute;
  right:0;
  top:0;
  z-index:-1
}

.plyr__ads>div,.plyr__ads>div iframe{
  height:100%;
  position:absolute;
  width:100%
}

.plyr__ads::after{
  background:#23282f;
  border-radius:2px;
  bottom:10px;
  bottom:10px;
  bottom:var(--plyr-control-spacing,10px);
  color:#fff;
  content:attr(data-badge-text);
  font-size:0.6875rem;
  padding:2px 6px;
  pointer-events:none;
  position:absolute;
  right:10px;
  right:10px;
  right:var(--plyr-control-spacing,10px);
  z-index:3
}

.plyr__ads::after:empty{
  display:none
}

.plyr__cues{
  background:currentColor;
  display:block;
  height:5px;
  height:5px;
  height:var(--plyr-range-track-height,5px);
  left:0;
  margin:-var(--plyr-range-track-height,5px)/2 0 0;
  opacity:.8;
  position:absolute;
  top:50%;
  width:3px;
  z-index:3
}

.plyr__preview-thumb{
  background-color:rgba(255,255,255,.9);
  background-color:rgba(255,255,255,.9);
  background-color:var(--plyr-tooltip-background,rgba(255,255,255,.9));
  border-radius:3px;
  bottom:100%;
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  box-shadow:0 1px 2px rgba(0,0,0,.15);
  box-shadow:var(--plyr-tooltip-shadow,0 1px 2px rgba(0,0,0,.15));
  margin-bottom:calc(calc(10px / 2) * 2);
  margin-bottom:calc(calc(10px/ 2) * 2);
  margin-bottom:calc(calc(var(--plyr-control-spacing,10px)/ 2) * 2);
  opacity:0;
  padding:3px;
  padding:3px;
  padding:var(--plyr-tooltip-radius,3px);
  pointer-events:none;
  position:absolute;
  transform:translate(0,10px) scale(.8);
  transform-origin:50% 100%;
  transition:transform .2s .1s ease,opacity .2s .1s ease;
  z-index:2
}

.plyr__preview-thumb--is-shown{
  opacity:1;
  transform:translate(0,0) scale(1)
}

.plyr__preview-thumb::before{
  border-left:4px solid transparent;
  border-left:4px solid transparent;
  border-left:var(--plyr-tooltip-arrow-size,4px) solid transparent;
  border-right:4px solid transparent;
  border-right:4px solid transparent;
  border-right:var(--plyr-tooltip-arrow-size,4px) solid transparent;
  border-top:4px solid rgba(255,255,255,.9);
  border-top:4px solid rgba(255,255,255,.9);
  border-top:var(--plyr-tooltip-arrow-size,4px) solid var(--plyr-tooltip-background,rgba(255,255,255,.9));
  bottom:calc(4px * -1);
  bottom:calc(4px * -1);
  bottom:calc(var(--plyr-tooltip-arrow-size,4px) * -1);
  content:'';
  height:0;
  left:50%;
  position:absolute;
  transform:translateX(-50%);
  width:0;
  z-index:2
}

.plyr__preview-thumb__image-container{
  background:#c1c8d1;
  border-radius:calc(3px - 1px);
  border-radius:calc(3px - 1px);
  border-radius:calc(var(--plyr-tooltip-radius,3px) - 1px);
  overflow:hidden;
  position:relative;
  z-index:0
}

.plyr__preview-thumb__image-container img{
  height:100%;
  left:0;
  max-height:none;
  max-width:none;
  position:absolute;
  top:0;
  width:100%
}

.plyr__preview-thumb__time-container{
  bottom:6px;
  left:0;
  position:absolute;
  right:0;
  white-space:nowrap;
  z-index:3
}

.plyr__preview-thumb__time-container span{
  background-color:rgba(0,0,0,.55);
  border-radius:calc(3px - 1px);
  border-radius:calc(3px - 1px);
  border-radius:calc(var(--plyr-tooltip-radius,3px) - 1px);
  color:#fff;
  font-size:0.8125rem;
  font-size:13px;
  font-size:var(--plyr-font-size-time,var(--plyr-font-size-small,13px));
  padding:3px 6px
}

.plyr__preview-scrubbing{
  bottom:0;
  filter:blur(1px);
  height:100%;
  left:0;
  margin:auto;
  opacity:0;
  overflow:hidden;
  pointer-events:none;
  position:absolute;
  right:0;
  top:0;
  transition:opacity .3s ease;
  width:100%;
  z-index:1
}

.plyr__preview-scrubbing--is-shown{
  opacity:1
}

.plyr__preview-scrubbing img{
  height:100%;
  left:0;
  max-height:none;
  max-width:none;
  -o-object-fit:contain;
     object-fit:contain;
  position:absolute;
  top:0;
  width:100%
}

.plyr--no-transition{
  transition:none!important
}

.plyr__sr-only{
  clip:rect(1px,1px,1px,1px);
  overflow:hidden;
  border:0!important;
  height:1px!important;
  padding:0!important;
  position:absolute!important;
  width:1px!important
}

.plyr [hidden]{
  display:none!important
}

:root {
  --plyr-color-main: #FFBC05
}

button.plyr__control--overlaid {
  width: 50px;
  height: 50px
}

button.plyr__control--overlaid::before {
    content: "";
    border: 2px solid #FFBC05;
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

button.plyr__control--overlaid svg {
    fill: #000000;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
  }

button.plyr__control--overlaid :hover {
    background: #000000;
  }

@media (min-width: 768px) {

button.plyr__control--overlaid {
    width: 70px;

    height: 70px
}

    button.plyr__control--overlaid::before {
      width: 80px;
      height: 80px;
    }

    button.plyr__control--overlaid svg {
      width: 25px;
      height: 25px;
    }
  }

.plyr__control.plyr__control--overlaid:hover {
  background: #000000
}

.plyr__control.plyr__control--overlaid:hover svg {
    fill: #ffffff;
  }

.plyr__control.plyr__control--overlaid:hover::before {
  border: 2px solid #ffffff;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  box-shadow: 0 3px 13px rgba(0,0,0,0.08);
}

.flatpickr-calendar.open,
  .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
  .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid rgba(72,72,72,0.2);
}

.flatpickr-calendar.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid rgba(72,72,72,0.2);
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
  .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
  .flatpickr-calendar.arrowRight:before,
  .flatpickr-calendar.rightMost:after,
  .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
  .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(72,72,72,0.2);
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #D29F13;
}

.flatpickr-calendar.arrowBottom:before,
  .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(72,72,72,0.2);
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #D29F13;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #D29F13;
  color: #fff;
  fill: #fff;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #fff;
  fill: #fff;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
  .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
  .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
        /*rtl:begin:ignore*/

/*
        /*rtl:end:ignore*/

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
        /*rtl:begin:ignore*/

/*
        /*rtl:end:ignore*/

.flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
  color: #bbb;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
  .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
  .flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
  .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
  .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72,72,72,0.15);
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}

.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72,72,72,0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72,72,72,0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(255,255,255,0.5);
}

.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
  .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255,255,255,0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #D29F13;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
  .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0,0,0,0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #D29F13;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: #D29F13;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #D29F13;
  color: rgba(255,255,255,0.85);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
  .flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
  border-left: 1px solid rgba(72,72,72,0.2);
  border-right: 1px solid rgba(72,72,72,0.2);
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 rgba(72,72,72,0.2);
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 2.4375rem;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
  .flatpickr-day.prevMonthDay.inRange,
  .flatpickr-day.nextMonthDay.inRange,
  .flatpickr-day.today.inRange,
  .flatpickr-day.prevMonthDay.today.inRange,
  .flatpickr-day.nextMonthDay.today.inRange,
  .flatpickr-day:hover,
  .flatpickr-day.prevMonthDay:hover,
  .flatpickr-day.nextMonthDay:hover,
  .flatpickr-day:focus,
  .flatpickr-day.prevMonthDay:focus,
  .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2;
}

.flatpickr-day.today {
  border-color: #bbb;
}

.flatpickr-day.today:hover,
  .flatpickr-day.today:focus {
  border-color: #bbb;
  background: #bbb;
  color: #fff;
}

.flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
  background: #D29F13;
  box-shadow: none;
  color: #fff;
  border-color: #D29F13;
}

.flatpickr-day.selected.startRange,
  .flatpickr-day.startRange.startRange,
  .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
  .flatpickr-day.startRange.endRange,
  .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #D29F13;
}

.flatpickr-day.selected.startRange.endRange,
  .flatpickr-day.startRange.startRange.endRange,
  .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
}

.flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover,
  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay,
  .flatpickr-day.notAllowed,
  .flatpickr-day.notAllowed.prevMonthDay,
  .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72,72,72,0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(72,72,72,0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #D29F13, 5px 0 0 #D29F13;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid rgba(72,72,72,0.2);
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 1.75rem;
}

.flatpickr-weekwrapper span.flatpickr-day,
  .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72,72,72,0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid rgba(72,72,72,0.2);
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 2.5rem;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #484848;
  font-size: 0.875rem;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
  .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
  .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
  background: #eaeaea;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (min-width: 640px){
  .sm\:bottom-3{
    bottom:0.75rem
  }

  .sm\:-mt-24{
    margin-top:-6rem
  }

  .sm\:-mt-40{
    margin-top:-10rem
  }
}

@media (min-width: 768px){
  .md\:bottom-5{
    bottom:1.25rem
  }

  .md\:left-12{
    left:3rem
  }

  .md\:order-1{
    order:1
  }

  .md\:order-2{
    order:2
  }

  .md\:mt-0{
    margin-top:0px
  }

  .md\:-mt-12{
    margin-top:-3rem
  }

  .md\:mr-0{
    margin-right:0px
  }

  .md\:mr-4{
    margin-right:1rem
  }

  .md\:mr-8{
    margin-right:2rem
  }

  .md\:block{
    display:block
  }

  .md\:inline-block{
    display:inline-block
  }

  .md\:grid{
    display:grid
  }

  .md\:hidden{
    display:none
  }

  .md\:w-60{
    width:15rem
  }

  .md\:w-auto{
    width:auto
  }

  .md\:w-full{
    width:100%
  }

  .md\:flex-none{
    flex:none
  }

  .md\:grid-cols-2{
    grid-template-columns:repeat(2, minmax(0, 1fr))
  }

  .md\:flex-row{
    flex-direction:row
  }

  .md\:flex-wrap{
    flex-wrap:wrap
  }

  .md\:items-center{
    align-items:center
  }

  .md\:justify-start{
    justify-content:flex-start
  }

  .md\:gap-x-10{
    grid-column-gap:2.5rem;
    -moz-column-gap:2.5rem;
         column-gap:2.5rem
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(1rem * var(--tw-space-x-reverse));
    margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse:0;
    margin-right:calc(3rem * var(--tw-space-x-reverse));
    margin-left:calc(3rem * calc(1 - var(--tw-space-x-reverse)))
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0px * var(--tw-space-y-reverse))
  }

  .md\:overflow-x-auto{
    overflow-x:auto
  }

  .md\:px-0{
    padding-left:0px;
    padding-right:0px
  }

  .md\:px-6{
    padding-left:1.5rem;
    padding-right:1.5rem
  }

  .md\:px-12{
    padding-left:3rem;
    padding-right:3rem
  }

  .md\:pt-4{
    padding-top:1rem
  }

  .md\:text-base{
    font-size:1rem;
    line-height:1.5rem
  }

  .md\:text-xl{
    font-size:1.25rem;
    line-height:1.75rem
  }

  .md\:text-1xl{
    font-size:1.3125rem;
    line-height:1.75rem
  }
}

@media (min-width: 1024px){
  .lg\:top-12{
    top:3rem
  }

  .lg\:top-20{
    top:5rem
  }

  .lg\:right-auto{
    right:auto
  }

  .lg\:right-full{
    right:100%
  }

  .lg\:bottom-1{
    bottom:0.25rem
  }

  .lg\:left-full{
    left:100%
  }

  .lg\:z-10{
    z-index:10
  }

  .lg\:col-span-5{
    grid-column:span 5 / span 5
  }

  .lg\:col-span-7{
    grid-column:span 7 / span 7
  }

  .lg\:col-span-12{
    grid-column:span 12 / span 12
  }

  .lg\:col-start-8{
    grid-column-start:8
  }

  .lg\:row-start-1{
    grid-row-start:1
  }

  .lg\:mx-auto{
    margin-left:auto;
    margin-right:auto
  }

  .lg\:mt-19{
    margin-top:4.75rem
  }

  .lg\:mt-40{
    margin-top:10rem
  }

  .lg\:mt-auto{
    margin-top:auto
  }

  .lg\:-mt-0{
    margin-top:0px
  }

  .lg\:-mt-12{
    margin-top:-3rem
  }

  .lg\:-mr-12{
    margin-right:-3rem
  }

  .lg\:-mr-24{
    margin-right:-6rem
  }

  .lg\:mb-0{
    margin-bottom:0px
  }

  .lg\:mb-8{
    margin-bottom:2rem
  }

  .lg\:mb-12{
    margin-bottom:3rem
  }

  .lg\:mb-16{
    margin-bottom:4rem
  }

  .lg\:mb-20{
    margin-bottom:5rem
  }

  .lg\:mb-40{
    margin-bottom:10rem
  }

  .lg\:ml-auto{
    margin-left:auto
  }

  .lg\:-ml-12{
    margin-left:-3rem
  }

  .lg\:-ml-20{
    margin-left:-5rem
  }

  .lg\:-ml-24{
    margin-left:-6rem
  }

  .lg\:block{
    display:block
  }

  .lg\:inline{
    display:inline
  }

  .lg\:flex{
    display:flex
  }

  .lg\:grid{
    display:grid
  }

  .lg\:hidden{
    display:none
  }

  .lg\:h-40{
    height:10rem
  }

  .lg\:max-h-160{
    max-height:40rem
  }

  .lg\:w-32{
    width:8rem
  }

  .lg\:w-40{
    width:10rem
  }

  .lg\:w-64{
    width:16rem
  }

  .lg\:w-112{
    width:28rem
  }

  .lg\:w-auto{
    width:auto
  }

  .lg\:w-header-lg{
    width:calc(100% + 60px)
  }

  .lg\:max-w-md{
    max-width:30rem
  }

  .lg\:max-w-2xl{
    max-width:42rem
  }

  .lg\:max-w-6xl{
    max-width:72rem
  }

  .lg\:rotate-140{
    --tw-rotate:140deg
  }

  .lg\:grid-cols-3{
    grid-template-columns:repeat(3, minmax(0, 1fr))
  }

  .lg\:grid-cols-12{
    grid-template-columns:repeat(12, minmax(0, 1fr))
  }

  .lg\:items-start{
    align-items:flex-start
  }

  .lg\:justify-end{
    justify-content:flex-end
  }

  .lg\:gap-8{
    grid-gap:2rem;
    gap:2rem
  }

  .lg\:gap-x-16{
    grid-column-gap:4rem;
    -moz-column-gap:4rem;
         column-gap:4rem
  }

  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse:0;
    margin-top:calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom:calc(0.75rem * var(--tw-space-y-reverse))
  }

  .lg\:overflow-visible{
    overflow:visible
  }

  .lg\:rounded-none{
    border-radius:0px
  }

  .lg\:rounded-br-5xl{
    border-bottom-right-radius:2.5rem
  }

  .lg\:bg-transparent{
    background-color:transparent
  }

  .lg\:bg-black-light{
    --tw-bg-opacity:1;
    background-color:rgba(31, 31, 31, var(--tw-bg-opacity))
  }

  .lg\:bg-beige{
    --tw-bg-opacity:1;
    background-color:rgba(246, 244, 236, var(--tw-bg-opacity))
  }

  .lg\:px-0{
    padding-left:0px;
    padding-right:0px
  }

  .lg\:px-10{
    padding-left:2.5rem;
    padding-right:2.5rem
  }

  .lg\:px-12{
    padding-left:3rem;
    padding-right:3rem
  }

  .lg\:py-6{
    padding-top:1.5rem;
    padding-bottom:1.5rem
  }

  .lg\:pt-0{
    padding-top:0px
  }

  .lg\:pt-16{
    padding-top:4rem
  }

  .lg\:pr-16{
    padding-right:4rem
  }

  .lg\:pr-24{
    padding-right:6rem
  }

  .lg\:pr-32{
    padding-right:8rem
  }

  .lg\:pb-0{
    padding-bottom:0px
  }

  .lg\:pb-8{
    padding-bottom:2rem
  }

  .lg\:pb-12{
    padding-bottom:3rem
  }

  .lg\:pb-20{
    padding-bottom:5rem
  }

  .lg\:pl-32{
    padding-left:8rem
  }

  .lg\:text-left{
    text-align:left
  }

  .lg\:text-base{
    font-size:1rem;
    line-height:1.5rem
  }

  .lg\:text-lg{
    font-size:1.125rem;
    line-height:1.75rem
  }

  .lg\:text-xl{
    font-size:1.25rem;
    line-height:1.75rem
  }

  .lg\:text-2xl{
    font-size:1.5rem;
    line-height:2rem
  }

  .lg\:text-3xl{
    font-size:1.875rem;
    line-height:2.25rem
  }

  .lg\:text-5xl{
    font-size:3rem;
    line-height:1
  }

  .lg\:text-1xl{
    font-size:1.3125rem;
    line-height:1.75rem
  }

  .lg\:text-2\.25xl{
    font-size:1.625rem;
    line-height:2.3125rem
  }

  .lg\:text-3\.5xl{
    font-size:2rem;
    line-height:2.3125rem
  }

  .lg\:text-4\.75xl{
    font-size:3rem;
    line-height:3.5625rem
  }

  .lg\:mask-1{
    -webkit-clip-path:url(#mask0);
    clip-path:url(#mask0)
  }
}

@media (min-width: 1280px){
  .xl\:top-28{
    top:7rem
  }

  .xl\:top-120{
    top:30rem
  }

  .xl\:bottom-4{
    bottom:1rem
  }

  .xl\:-mt-16{
    margin-top:-4rem
  }

  .xl\:flex{
    display:flex
  }

  .xl\:hidden{
    display:none
  }

  .xl\:max-h-200{
    max-height:50rem
  }

  .xl\:w-40{
    width:10rem
  }

  .xl\:w-80{
    width:20rem
  }

  .xl\:w-header-xl{
    width:calc(100% + 80px)
  }

  .xl\:pt-24{
    padding-top:6rem
  }

  .xl\:pr-32{
    padding-right:8rem
  }

  .xl\:pb-32{
    padding-bottom:8rem
  }

  .xl\:pl-32{
    padding-left:8rem
  }

  .xl\:text-xl{
    font-size:1.25rem;
    line-height:1.75rem
  }

  .xl\:text-2xl{
    font-size:1.5rem;
    line-height:2rem
  }

  .xl\:text-6xl{
    font-size:3.75rem;
    line-height:1
  }

  .xl\:text-1xl{
    font-size:1.3125rem;
    line-height:1.75rem
  }

  .xl\:text-3\.5xl{
    font-size:2rem;
    line-height:2.3125rem
  }
}

@media (min-width: 1536px){
}

@media (min-width: 1440px){
  .xxl\:inline{
    display:inline
  }

  .xxl\:flex{
    display:flex
  }

  .xxl\:hidden{
    display:none
  }

  .xxl\:flex-shrink-0{
    flex-shrink:0
  }
}

@media (orientation: portrait) and (max-width: 767px){
  .portrait\:block{
    display:block
  }

  .portrait\:hidden{
    display:none
  }
}

